
import React from "react";
import i18n from "i18next";
import {cssTransition, toast} from "react-toastify";

import { ASC,
    DEFAULT_ERROR_OBJECT,
    DESC,
    TO_COUNTRY,
    COUNTRY, 
    PERATION,
    SEND_BY,
    SERVICE_TYPE,
    TRACKER,
    OPERATION,
    ESTIMATED_DELIVERY,
} from '../constants'
import { setAlert, setList, setLoadSpinner, setModalForm, setModalForm2, setSelectedList } from "../storage/global";
import { EN, CN } from "../components/shared/LangBlock";
import { getNewParcels, getTrackers }    from "./api";

export function unselectList(selectedList = [], all = false) {
    if (all) {
        const els = document.querySelectorAll('.table-item-selected')
        if (els && els.length) {
            els.forEach((el => el.className = el.className.replace('table-item-selected', '').trim()))
        }
    } else {
        selectedList.forEach(parcel => {
            const el = document.querySelector(`[data-uid='${parcel.uid}']`)
            if (el)
                el.className = el.className.replace('table-item-selected', '').trim()
        })
    }
}

export function selectList(selectedList = [], all = false) {
    if (all) {
        const els = document.querySelectorAll('.table-row-item')
        if (els && els.length) {
            els.forEach((el =>  el.className = el.className + ' table-item-selected'))
        }
    } else {
        selectedList.forEach(parcel => {
            const el = document.querySelector(`[data-uid='${parcel.uid}']`)
            if (el)
                el.className = el.className + ' table-item-selected'
        })
    }
}

export function filtersByColumnsList(list, filtersSelected) {
    const correctFilters = filtersSelected.filter( (item) => {
        return item.filterSelected && (item.subfilterSelected || typeof item.subfilterSelected === 'number');
    });
    const filtersByTypes = correctFilters.reduce( (beforeObj, filter) => {
        const newObj = beforeObj;
        const {filterSelected, subfilterSelected} = filter;
        if(!newObj[filterSelected]) newObj[filterSelected] = [subfilterSelected]
        else newObj[filterSelected].push(subfilterSelected);
        return newObj
    }, {});

    return list.filter(item => {
        let correctAllFilter = true;
        for (const filterSelected of Object.keys(filtersByTypes)) {
            const subArr = filtersByTypes[filterSelected];
            let correct = false;
            for (const subfilterSelected of subArr) {
                const search = subfilterSelected;
                const regex = new RegExp(`(${search})`, 'i');
                let itemVal = typeof item[filterSelected] === 'string' ? item[filterSelected] : '';
                correct = !search.length || (search.length && (regex.test(itemVal) || search === itemVal));
                if(correct) break;
            }
            if(!correct) {
                correctAllFilter = false;
                break;
            }
        }
        return correctAllFilter;
    })
}

export async function sortedList(list, name, direction, search = '', isDate = false, listForFilter) {

    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    const regex = new RegExp(`(${search})`, 'i');
    return list.filter(item => {
        const itemVal = JSON.stringify(Object.values(item))
        return (!search.length && (!listForFilter || !listForFilter.length))
                || (search.length && regex.test(itemVal))
                || (listForFilter && listForFilter.find( item => itemVal.includes(item)));
    }).sort((a, b) => {
        if (isDate) {
            const date1 = a.date ?? a.sentdate ?? a.Data;
            const date2 = b.date ?? b.sentdate ?? b.Data;
            const splitDateFirst = date1.split('.');
            const splitDateSecond = date2.split('.');
            const dateFirst = new Date(`${splitDateFirst[2]}-${splitDateFirst[1]}-${splitDateFirst[0]}`);
            const dateSecond = new Date(`${splitDateSecond[2]}-${splitDateSecond[1]}-${splitDateSecond[0]}`);

            if (direction === DESC)
                return collator.compare(dateFirst.getTime(), dateSecond.getTime()) * -1
            return collator.compare(dateFirst.getTime(), dateSecond.getTime())
        } else {
            if (direction === DESC)
                return collator.compare(a[name], b[name]) * -1
            return collator.compare(a[name], b[name])
        }
    })
}

export function getDirection(currentOrder, name) {
    return currentOrder.name === name
        ? currentOrder.direction === ASC ? DESC : ASC
        : DESC
}

export function handleOrder(rootItems, name, direction, search, state, setState, iop, isDate) {

    return sortedList(rootItems, name, direction, search, isDate)
        .then((list => {
            setState({
                ...state,
                rootItems,
                items: list,
                itemsView: list.slice(0, iop),
                page: 2,
                search,
                order: {
                    name,
                    direction
                }
            })
            return list;
        }))
        .then((list) => {
            unselectList([], true)
            return list;
        })
}

export function requestDeleteMethod(dispatch, getList, deleteMethod, t) {
    return (selectedList) => {
        return deleteMethod(selectedList)
            .then((response) => {
                viewAlert(dispatch, response)
            })
            .then(() => {
                dispatch(setSelectedList([]))
                dispatch(setLoadSpinner(false));
                getList()
                    .then(response => {
                        dispatch(setList(response.data, t('noStatus')))
                    })
                    .catch(({response}) => {
                        viewAlert(dispatch, response)
                    })
                    .finally(() => dispatch(setLoadSpinner(false)));
            })
            .catch(({response}) => {
                viewAlert(dispatch, response)
            })
    }
}

export function submitFormByRef(ref) {
    ref.current.querySelector('[type="submit"]').click()
}

export function serializeFormToObject(form:Object) {
    const data = {};

    Object.values(form)
        .filter(item => ['SELECT', 'INPUT'].includes(item.tagName))
        .map(value => {
            return {
                name: value.id,
                value: value.value
            }
        })
        .map(v => data[`${v.name}`] = v.value)

    return data;
}

export function serializeFormToArray(form) {

    return Object.values(form)
        .filter(item => ['SELECT', 'INPUT'].includes(item.tagName))
        .map(value => {
            return {
                name: value.id,
                value: value.value
            }
        })
}

export function hideModalForm(dispatch) {
    return () => {
        dispatch(setModalForm({
            show: false,
            withoutClose: false,
        }))
    }
}

export function hideModalForm2(dispatch) {
    return () => {
        dispatch(setModalForm2({
            show: false
        }))
    }
}

export function validate(value, validations, setError, defaultError) {
    const newError = {...defaultError}
    if (validations && Array.isArray(validations)) {
        validations.forEach(f => {
            const message = f(value);
            if (message.length && !newError.isError) {
                newError.isError = true;
                newError.message = message;
            }
        })
    }
    setError && setError(newError)
    return newError;
}

export function checkValidate(value, validations, setError) {
    return validate(value, validations, setError, DEFAULT_ERROR_OBJECT);
}

export function viewAlert(dispatch:Object, response:Object, alert:Object) {
    if (!response)
        response = {status: 0, statusText: ''}
    if (response.status >= 400) {
        let statusText = response.statusText && response.statusText.match(/(Resolving timed out after)|(Operation timed out)/ig) ?
            'An error has occurred. Please try again later.'
            : response.statusText;
        let statusTextError = response.data?.statusTextError;
        if(typeof response.data === 'string') {
            const splitData = response.data.split(/\r?\n\r?\n/);
            const jsonBody = splitData[1];
            if(jsonBody) {
                const jb = JSON.parse(jsonBody);
                if(jb) statusTextError = jb.statusTextError;
            }
        }
        let textError = statusTextError || statusText;
        dispatch(setAlert({
            text: textError,
            type: 'danger',
            ...alert,
        }))
    } else {
        dispatch(setAlert({
            text: response.statusText,
            type: 'success',
            ...alert,
        }))
    }
}

export function modifyPhoneNumber(value) {
    return value.toString().replaceAll(/\D/ig, '');
}

export function goTrackingSystem(uid:string, dispatch) {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = 'https://track.meestcn.cn/?search=' + uid
    a.click();
}

export function upperCaseFirstLetter(text:string) {
    return text.length > 1 ? text.slice(0, 1).toUpperCase() + text.slice(1) : text.toUpperCase();
}

export function parsePassport(value:string|[]):{series: string, number: number} {
    if (Array.isArray(value))
        value = value.join();
    if (value && value.length > 2) {
        return {series: value.slice(0, 2), number: value.slice(2)}
    }
    return {series:'', number:value}
}

export function getExactTime(time: string, withHour: boolean) {
    const data = new Date(time);
    const d = data.getDate();
    const mo = (Number(data.getMonth()) + 1);
    const h = data.getHours();
    const m = data.getMinutes();
    const data_decode = time ?
        add0(d) + '.' + add0(mo) + '.' + data.getFullYear() +
            (withHour ? (' ' + add0(h) + ':' + add0(m)) : '')
        : time;

    return data_decode;
}

export function getExactTimeFullYear(time: string, withHour: boolean) {
    const data = new Date(time);
    const d = data.getDate();
    const mo = (Number(data.getMonth()) + 1);
    const h = data.getHours();
    const m = data.getMinutes();
    const s = data.getSeconds();
    const data_decode = time ?
        data.getFullYear() + '-' + add0(mo) + '-' + add0(d) + ' ' +
            (withHour ? (' ' + add0(h) + ':' + add0(m) + ':' + add0(s)) : '')
        : time;

    return data_decode;
}

const add0 = (n: number) => (n < 10) ? ('0' + n) : n;

export const translateStatus = (postStatus: StatusInterface): string => {
    if(i18n.language === CN) {
        const localizationData = postStatus.localizationData.find((localization) => {
            return localization.code == 'zh';
        });
        return localizationData.name
    }
    return postStatus.localizationData.find((localization) => {
        return localization.code == EN;
    }).name;
}

export const getStatus = (statuses: any[], statusCode: string): string => {
    if (!statusCode) return null;
    const c = statuses.find( c => c.code == statusCode);
    return c ? translateStatus(c) : statusCode;
}

export function copy(value: string) {
    const zoom = cssTransition({
        enter: "animate__animated animate__zoomIn",
        exit: "animate__animated animate__zoomOut"
    })

    navigator.clipboard.writeText(value)
        .then(() => {
            return toast.info(<div className={'text-center'}>{i18n.t('copied')}</div>, {
                autoClose: 500,
                position: "bottom-center",
                theme: 'colored',
                transition: zoom,
                hideProgressBar: true
            });
        })
        .then(id => {
            setTimeout(() => {
                const elem = document.getElementById(id);
                if (elem) {
                    elem.style.width = '200px'
                    elem.style.marginLeft = 'auto'
                    elem.style.marginRight = 'auto'
                }
            }, 5)
            // if (elem)
        })
        .catch()
}

export function debounce<A = unknown, R = void>(
    fn: (args: A) => Promise<R>, ms: number
): [(args: A) => Promise<R>, () => void] {

    let timer: NodeJS.Timeout;

    const debouncedFunc = (args: A): Promise<R> =>
        new Promise((resolve) => {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                resolve(fn(args));
            }, ms);
        });

    const teardown = () => clearTimeout(timer);

    return [debouncedFunc, teardown];
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getList(dispatch, t) {
    return getNewParcels()
        .then(({data}) => {
            const list = data.map(v => {
                switch (Number(v.sensitive_goods)) {
                    case 0:
                        v.sensitive_goods = t('simple_goods');
                        break;
                    case 1:
                        v.sensitive_goods = t('sensitive_goods');
                        break;
                    case 2:
                        v.sensitive_goods = t('pure_buttery');
                        break;
                    default:
                        break;
                }

                return v;
            }).sort( (p1, p2) => Number(new Date(p2.time_of_create)) - Number(new Date(p1.time_of_create)))
            dispatch(setList(list, t('noStatus')))
            return {
                data: list
            }
        })
        .then(data => {
            orderedHook(dispatch, t, data.data)
            return data;
        })
}
function orderedHook(dispatch, t, parcels) {
    if (parcels && parcels.length) {
        const trackersFromLocalStorageJSON = localStorage.getItem('trackers');
        let trackers = {};
        let expire = 0;
        const currentTimestamp = new Date().getTime();
        if (trackersFromLocalStorageJSON) {
            const trackersFromLocalStorage = JSON.parse(trackersFromLocalStorageJSON)
            if (trackersFromLocalStorage) {
                expire = Number(trackersFromLocalStorage.expire);
                const isExpire = currentTimestamp > expire
                if (!isExpire) {
                    trackers = {...trackersFromLocalStorage.list};
                }
            }
        }
        const loadCodes = [];
        for (let index = 0; index < parcels.length; index++) {
            const parcel = parcels[index];

            if (!trackers[parcel.code] || trackers[parcel.code].eventDescr_descrEN === "No status") {
                loadCodes.push(parcel.code);
            }
        }
        loadCodes.length && getTrackers(loadCodes, dispatch, t('noStatus'));
    }
}

export function goToSite(ln) {
    const url = process.env.SITE_URL || 'https://meestcn.cn/';
    const url_en = process.env.SITE_URL_EN || 'https://meestcn.cn/en/';
    window.open(ln === 'en' ? url_en : url, '_blank');
}

export function downloadWeChatQR() {
    const imagePath = '/assets/img/WeChatQR.png';
    const fullImageUrl = window.location.origin + imagePath;
    const link = document.createElement('a');
    link.href = fullImageUrl;
    link.download = 'WeChatQR.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const accountingFilters = (t) => [
    {
        keyFilter: OPERATION,
        nameFilter: t('operation'),
    },
]

export const newParcelsFilters = (t) => [
    {
        keyFilter: SEND_BY,
        nameFilter: t('ship'),
    },
    {
        keyFilter: TO_COUNTRY,
        nameFilter: t('dest_country'),
    },
    {
        keyFilter: SERVICE_TYPE,
        nameFilter: t('TypeDelivery'),
    },
    {
        keyFilter: TRACKER,
        nameFilter: t('tracking'),
    },
]

export const sentParcelsFilters = (t) => [
    {
        keyFilter: ESTIMATED_DELIVERY,
        nameFilter: t('Estimated_date_delivery_customs'),
    }
]