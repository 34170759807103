
import React, { useState, useEffect }        from 'react';
import { useTranslation }         from "react-i18next";
import { useDispatch, useSelector }            from "react-redux";

import {
    DEFAULT_ERROR_OBJECT,
    SEND_BY,
    TO_COUNTRY,
    ESTIMATED_DELIVERY,
    TRACKER,
    SERVICE_TYPE,
    OPERATION,
} from "../../constants";
import ModalButtons                         from "./modal/ModalButtons";
import Select                               from "../input/Select";
import { hideModalForm,
    accountingFilters,
    newParcelsFilters,
    sentParcelsFilters,
} from "../../utils/misc";
import { SET_KEY }       from "../../storage/global";

const ModalFilterOption = ({page, isMobile}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const nothingSelectedFilter = {keyFilter: '', nameFilter: t('nothingSelected')}
    const nothingSelectedSubfilter = {keySubfilter: '', nameSubfilter: t('nothingSelected')}

    const list = useSelector(state => state.global.list);
    const filtersSelectedAccounting = useSelector(state => state.global.filtersSelectedAccounting);
    const filtersSelectedNewParcels = useSelector(state => state.global.filtersSelectedNewParcels);
    const filtersSelectedWaitingArrival = useSelector(state => state.global.filtersSelectedWaitingArrival);
    const filtersSelectedSentParcels = useSelector(state => state.global.filtersSelectedSentParcels);

    const filtersList = page === 'accounting' ? [
        nothingSelectedFilter,
        ...accountingFilters(t),
    ]
    : [
        nothingSelectedFilter,
        ...newParcelsFilters(t),
        ...(page === 'sentParcels' ? sentParcelsFilters(t) : []),
    ];

    const filtersListOptionsDef = {
        ['']: [
            nothingSelectedSubfilter,
        ],
        [SEND_BY]: [
            nothingSelectedSubfilter,
            {
                keySubfilter: 'AIR',
                nameSubfilter: 'AIR',
            },
            {
                keySubfilter: 'SEA',
                nameSubfilter: 'SEA',
            },
            {
                keySubfilter: 'TRUCK',
                nameSubfilter: 'TRUCK',
            },
        ],
        [TO_COUNTRY]: [
            nothingSelectedSubfilter,
        ],
        [SERVICE_TYPE]: [
            nothingSelectedSubfilter,
            {
                keySubfilter: '1',
                nameSubfilter: t('PUDO'),
            },
            {
                keySubfilter: '0',
                nameSubfilter: t('toDoor'),
            },
        ],
        [TRACKER]: [
            nothingSelectedSubfilter,
        ],
        [ESTIMATED_DELIVERY]: [
            nothingSelectedSubfilter,
        ],
        [OPERATION]: [
            nothingSelectedSubfilter,
        ],
    };

    function getStatusesAndEstimatedDelivery(list) {
        return list.reduce( (beforeArr, parcel) => {
            const newArr = beforeArr;
            if(!newArr[0].includes(parcel[TRACKER])) newArr[0].push(parcel[TRACKER]); // status
            if(typeof parcel[ESTIMATED_DELIVERY] === 'string')
                if(!newArr[1].includes(parcel[ESTIMATED_DELIVERY])) newArr[1].push(parcel[ESTIMATED_DELIVERY]);
            if(!newArr[2].includes(parcel[OPERATION])) newArr[2].push(parcel[OPERATION]);
            if(!newArr[3].includes(parcel[TO_COUNTRY])) newArr[3].push(parcel[TO_COUNTRY]);
            return newArr;
        }, [[],[],[],[]])
    }

    function setFiltersSelected(value) {
        dispatch({type: SET_KEY, payload: {
            key: page === 'accounting' ? 'filtersSelectedAccounting'
                : page === 'newParcels' ? 'filtersSelectedNewParcels'
                : page === 'waitingArrival' ? 'filtersSelectedWaitingArrival'
                : 'filtersSelectedSentParcels',
            value,
        }})
    }

    function getFilterListOptions() {
        let filterListOptions_ = filtersListOptionsDef;
        const [newStatuses_, newEstimatedDeliveres_, newOperation_, newCountry_] = getStatusesAndEstimatedDelivery(list)
        let newStatuses = newStatuses_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        let newEstimatedDeliveres = newEstimatedDeliveres_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        let newOperation = newOperation_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        let newCountry = newCountry_.map((el) => ({keySubfilter: el, nameSubfilter: el}));
        filterListOptions_[TRACKER] = [{keySubfilter: '', nameSubfilter: t('nothingSelected')}, ...newStatuses];
        filterListOptions_[ESTIMATED_DELIVERY] = [{keySubfilter: '', nameSubfilter: t('nothingSelected')}, ...newEstimatedDeliveres];
        filterListOptions_[OPERATION] = [{keySubfilter: '', nameSubfilter: t('nothingSelected')}, ...newOperation];
        filterListOptions_[TO_COUNTRY] = [{keySubfilter: '', nameSubfilter: t('nothingSelected')}, ...newCountry];
        return filterListOptions_
    }

    const filtersSelected = page === 'accounting' ? filtersSelectedAccounting
        : page === 'newParcels' ? filtersSelectedNewParcels
        : page === 'waitingArrival' ? filtersSelectedWaitingArrival
        : filtersSelectedSentParcels
    const [localFiltersSelected, setLocalFilters] = useState(filtersSelected.length ? filtersSelected : [{
        filterSelected: '',
        subfilterSelected: '',
    }])
    const [filterListOptions, setFilterListOptions] = useState(getFilterListOptions())
    const [errorFilter, setErrorFilter] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorSubfilter, setErrorSubfilter] = useState({...DEFAULT_ERROR_OBJECT});

    useEffect(() => {
        if(list) {
            const filterListOptions_ = getFilterListOptions();
            setFilterListOptions(filterListOptions_);
        }
    }, [list])

    function clearFilter() {
        dispatch({type: SET_KEY, payload: {
            key: 'applyFilter',
            value: false,
        }})
        setFiltersSelected([]);
        hideModalForm(dispatch)();
    }

    function saveAndFilter() {
        setFiltersSelected(localFiltersSelected);
        dispatch({type: SET_KEY, payload: {
            key: 'applyFilter',
            value: true,
        }})
        hideModalForm(dispatch)()
    }

    return (
        <div className='modal-filter-options'>
            {localFiltersSelected.map((fltr, i) => {
                return (<div className="firtersOptions row mb-2" key={i}>
                    <Select
                        id={'filters'}
                        classNameContainer={'col-12 col-md-6 mb-2'}
                        className={''}
                        options={filtersList}
                        selectValue={filtersList.find((el) => el.keyFilter == fltr.filterSelected)}
                        errorMessage={errorFilter.message}
                        loaded={false}
                        keyName={'keyFilter'}
                        nameValue={'nameFilter'}
                        handleChange={(el, id) => {
                            const newFilter = [...localFiltersSelected];
                            newFilter[i].filterSelected = el.keyFilter;
                            setLocalFilters(newFilter)
                        }}
                    />
                    <Select
                        id={'filtersOptions'}
                        className={'maxh-170'}
                        classNameContainer={`col-10 col-md-5 ${isMobile ? 'mt-2' : ''}`}
                        options={filterListOptions[fltr.filterSelected]}
                        selectValue={filterListOptions[fltr.filterSelected].find((el) => el.keySubfilter == fltr.subfilterSelected)}
                        errorMessage={errorSubfilter.message}
                        // loaded={loadSubfilter}
                        keyName={'keySubfilter'}
                        nameValue={'nameSubfilter'}
                        handleChange={(el, id) => {
                            const newFilter = [...localFiltersSelected];
                            newFilter[i].subfilterSelected = el.keySubfilter;
                            setLocalFilters(newFilter)
                        }}
                    />
                    <div className={'col-2 col-md-1 d-flex align-items-center justify-content-center cursor-pointer mb-2'}>
                        <img
                            onClick={(ev) => {
                                const newFilter = [...localFiltersSelected];
                                newFilter.splice(i, 1);
                                setLocalFilters(newFilter)
                            }}
                            className={''}
                            src="/assets/img/close.svg"
                            alt="close"
                            width={24}
                            height={24}
                        />
                    </div>
                </div>)
            })}
            <div className="firtersOptions row mb-2">
                <div className="col-10 col-md-11"></div>
                <div className={'col-2 col-md-1 d-flex align-items-center justify-content-center cursor-pointer'}>
                    <i
                        className={`fas fa-plus`}
                        onClick={(ev) => {
                            setLocalFilters([...localFiltersSelected, {filterSelected: '', subfilterSelected: ''}]);
                        }}
                    />
                </div>
            </div>
            <ModalButtons buttons={{
                cancel: {
                    text: t('clear'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: clearFilter,
                },
                save: {
                    text: t('Apply_settings'),
                    variant: 'primary',
                    type: 'button',
                    action: saveAndFilter
                }
            }}/>
        </div>
    );
};

export default ModalFilterOption;