import React, { memo, useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";

import "react-multi-carousel/lib/styles.css";
import CarouselElement from "./CarouselElement";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 2.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1200, min: 768 },
        items: 1,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};
const Footer = ({ footerIsOpen, toggleFooterIsOpen, onPromocodesClick, onRefPromocodeClick }) => {
    const { t, i18n } = useTranslation();
    const isMobile = useSelector(state => state.global.isMobile);
    const newsCountRoot = useSelector(state => state.global.newsCountRoot);
    const newShowTimeRoot = useSelector(state => state.global.newShowTimeRoot);
    const newsStopCarousel = useSelector(state => state.global.newsStopCarousel);
    const news = useSelector(state => state.global.news);

    const [newsList, setNewsList] = useState(filterNews(news, +newsCountRoot));
    const [pause, setPause] = useState(false);

    useEffect(() => {
        setNewsList(filterNews(news, +newsCountRoot));
    }, [news, newShowTimeRoot, newsStopCarousel]);

    const handleCarouselNavigation = (direction) => {
        trackEvent(AMPLITUDE_EVENTS.CAROUSEL_NAVIGATION, {
            pageSection: 'footer',
            action: direction,
            deviceType: isMobile ? 'mobile' : 'desktop'
        });
    };

    const ButtonGroup = ({ next, previous, goToSlide }) => {
        return (
            <div className="carousel-button-group">
                <div
                    onClick={() => {
                        handleCarouselNavigation('previous');
                        previous();
                    }}
                    className={'carousel-button cursor-pointer'}
                    style={{
                        left: 0,
                        transform: 'rotateZ(180deg)'
                    }}
                >
                    <img width='20' src="/assets/img/right_arrow.svg" alt="previous"/>
                </div>
                <div
                    onClick={() => {
                        handleCarouselNavigation('next');
                        next();
                    }}
                    className={'carousel-button cursor-pointer'}
                    style={{
                        right: 0
                    }}
                >
                    <img width='20' src="/assets/img/right_arrow.svg" alt="next"/>
                </div>
            </div>
        );
    };

    const CustomDot = ({ onClick, ...rest }) => {
        const {
            index,
            active,
            carouselState: { currentSlide }
        } = rest;

        return (
            <button
                className={`${active ? "active" : "inactive"} mx-1 figma-dots bgcol-figma-accent opacity-04`}
                onClick={() => {
                    trackEvent(AMPLITUDE_EVENTS.CAROUSEL_DOT_CLICK, {
                        pageSection: 'footer',
                        dotIndex: index,
                        deviceType: isMobile ? 'mobile' : 'desktop'
                    });
                    onClick();
                }}
            >
            </button>
        );
    };

    const handleFooterToggle = () => {
        trackEvent(AMPLITUDE_EVENTS.FOOTER_TOGGLE, {
            pageSection: 'footer',
            action: footerIsOpen ? 'close' : 'open',
            deviceType: isMobile ? 'mobile' : 'desktop'
        });
        toggleFooterIsOpen();
    };

    function filterNews(news_, newsCountRoot_) {
        const langEng = i18n.language === 'en';
        if(news_ && news_.length) {
            let listNews = news_.filter(post => langEng ? post.lang === 'en' : post.lang !== 'en');
            if(newsCountRoot_ > 0 && listNews.length > newsCountRoot_) {
                listNews = listNews.slice(0, newsCountRoot_);
            }
            return listNews;
        }
        return [];
    }

    return (
        <footer className="" data-footerisopen={footerIsOpen}>
            <div className={"p-0 footer-mobile"}>
                <div className="carousel-container">
                    {footerIsOpen && newsList.length ? (
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            showDots={isMobile}
                            customDot={<CustomDot />}
                            responsive={responsive}
                            ssr={false}
                            infinite={true}
                            autoPlay={!newsStopCarousel}
                            autoPlaySpeed={pause ? 1000000000 : newShowTimeRoot*1000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="desk-carousel"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={''}
                            dotListClass=""
                            itemClass=""
                            arrows={false}
                            customButtonGroup={isMobile ? null : <ButtonGroup/>}
                        >
                            {newsList.map((item, key) => (
                                <CarouselElement
                                    key={key}
                                    ind={key}
                                    {...item}
                                    onPromocodesClick={onPromocodesClick}
                                    onRefPromocodeClick={onRefPromocodeClick}
                                />
                            ))}
                        </Carousel>
                    ) : null}
                </div>
                <div className="btn-hide-news px-4 cursor-pointer">
                    <i
                        className={`fas fa-solid ${footerIsOpen ? 'fa-chevron-down' : 'fa-chevron-up'}`}
                        style={{lineHeight: '20px', fontSize: 20}}
                        onClick={handleFooterToggle}
                    />
                </div>
            </div>
        </footer>
    );
};

export default memo(Footer);
