import React from 'react';

const AnimatedDeliveryBox = () => {
    return (
        <div className="delivery-container">
            <style>
                {`
          .delivery-container {
            width: 600px;
            height: 600px;
            position: relative;
          }

          .box-image {
            width: 100%;
            height: 100%;
            background: url('/assets/img/box_spinner.png') no-repeat center;
            background-size: contain;
            animation: float 3s ease-in-out infinite;
            position: relative;
          }

          .smoke {
            position: absolute;
            right: 75%;
            bottom: 35%;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.9);
            filter: blur(7px);
            animation: smoke 2s infinite;
            opacity: 0;
          }

          .smoke-2 {
            position: absolute;
            right: 70%;
            bottom: 33%;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.8);
            filter: blur(8px);
            animation: smoke-2 2.5s infinite;
            opacity: 0;
            animation-delay: 1s;
          }

          .smoke-3 {
            position: absolute;
            right: 68%;
            bottom: 37%;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.7);
            filter: blur(9px);
            animation: smoke-3 3s infinite;
            opacity: 0;
            animation-delay: 0.5s;
          }

          @keyframes float {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
          }

          @keyframes smoke {
            0% {
              opacity: 0;
              transform: translate(0, 0) scale(1);
            }
            20% {
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: translate(-60px, -20px) scale(4);
            }
          }

          @keyframes smoke-2 {
            0% {
              opacity: 0;
              transform: translate(0, 0) scale(1.2);
            }
            25% {
              opacity: 0.8;
            }
            100% {
              opacity: 0;
              transform: translate(-80px, -30px) scale(5);
            }
          }

          @keyframes smoke-3 {
            0% {
              opacity: 0;
              transform: translate(0, 0) scale(1.5);
            }
            30% {
              opacity: 0.6;
            }
            100% {
              opacity: 0;
              transform: translate(-100px, -40px) scale(6);
            }
          }
        `}
            </style>
            <div className="box-image">
                <div className="smoke" />
                <div className="smoke-2" />
                <div className="smoke-3" />
            </div>
        </div>
    );
};

export default AnimatedDeliveryBox;
