import React from 'react';
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { setModalForm } from "../../../storage/global";

const FullWidthModalFormMobile = ({fullScreen, isOpen, title, form, withoutClose = null}) => {
    const modalForm = useSelector(state => state.global.modalForm)
    const dispatch = useDispatch();

    const myForm = {
        show: isOpen ?? modalForm.show,
        withoutClose: withoutClose != null ? withoutClose : modalForm.withoutClose,
        title: title ?? modalForm.title,
        form: form ?? modalForm.form,
        fullScreen: fullScreen ?? modalForm.fullScreen,
        hideOut: modalForm.hideOut,
        styles: modalForm.styles,
        formContent: modalForm.formContent,
        formBody: modalForm.formBody,
        hideCloseButton: !!(modalForm.hideCloseButton ?? false)
    }

    if (!myForm.show) return null;

    function hide(e) {
        dispatch(setModalForm({
            ...myForm,
            show: false,
            formContent: null,
        }))
    }

    function hideOut(e) {
        if (e.target.id === 'mobile-modal-form' && myForm.hideOut)
            hide(e)
    }

    return ReactDOM.createPortal(
        <div
            id={'mobile-modal'}
            className={` mobile mobile-modal-form fixed inset-0 flex flex-col bg-white
                ${myForm.fullScreen ? 'mobile-modal-fullscreen' : ''}`}
            onClick={hideOut}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1050,
                backgroundColor: '#fff',
                ...myForm.styles
            }}
        >
            <div className="mobile-modal-wrapper h-full">
                <div
                    id={'mobile-modal-content mobile-modal'}
                    className={`mobile-modal-content h-full flex flex-col
                        ${myForm.formContent ? myForm.formContent.classNames : ''}`}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        minWidth: '100%',

                    }}
                >
                    {myForm.title && (
                        <div className="mobile-form-modal-header" style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: '#fff',
                            zIndex: 10
                        }}>
                        {!myForm.withoutClose && !myForm.hideCloseButton && (
                            <button
                                onClick={hide}
                                className="mobile-modal-close"
                                style={{
                                    padding: '8px',
                                    borderRadius: '50%',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer'
                                }}
                            >
                                <img
                                    src="/assets/img/close.svg"
                                    alt="close"
                                    style={{
                                        width: '24px',
                                        height: '24px'
                                    }}
                                />
                            </button>
                        )}
                        </div>
                    )}

                    <div
                        className={`mobile-modal-body ${myForm.formBody ? myForm.formBody.classNames : ''}`}
                        style={{
                            flex: 1,
                            overflowY: 'auto',
                            WebkitOverflowScrolling: 'touch'
                        }}
                    >
                        {myForm.form}
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default FullWidthModalFormMobile;
