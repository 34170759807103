import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";

import ModalButtons from "../shared/modal/ModalButtons";
import {hideModalForm, serializeFormToObject} from "../../utils/misc";
import {calculateTotalPay, createPayment, makePostRequest} from "../../utils/api";
import {setList, setLoadSpinner} from "../../storage/global";
import InputText from "../input/InputText";
import {PROMOCODE} from "../../constants";
import InfoQuestion from "../shared/InfoQuestion";

const PayParcelsMobileForm = ({parcels}) => {
    const [refCheck, setRefCheck] = useState({});
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const list = useSelector(state => state.global.list);

    const [payTotal, setPayTotal] = useState(0)
    const [promocode, setPromocode] = useState('')
    const [promocodeIsValid, setPromocodeIsValid] = useState(false)
    const [promocodeError, setPromocodeError] = useState('')
    const [promocodeData, setPromocodeData] = useState(null)

    useMemo(() => {
        calculate()
    }, [parcels])

    const discount = useMemo(() => {
        if (parcels.length === 1 && !!payTotal) {
            const totalSum = Number(parcels[0].sum) - payTotal;
            return !!totalSum && '-' + totalSum;
        }
        return '';
    }, [promocodeData, payTotal])

    async function calculate(promocodeData = {}) {
        calculateTotalPay({parcels, promocodeData}).then(({data}) => {
            setPayTotal(data.total)
        })
    }

    function resetVal(statusText, valid = false, promocodeData = null) {
        setPromocodeError(statusText);
        setPromocodeIsValid(valid);
        setPromocodeData(promocodeData);
        calculate(promocodeData)
    }

    async function checkPromocode() {
        if(parcels[0].country) {
            const params = {
                lang: i18n.language === 'cn' ? 'zh' : 'en',
                promocode,
                country: parcels[0].country,
                barcode: parcels[0].code,
            };
            const res = await makePostRequest('/api/misc/CheckRelevancePromocodeChina', params, {}).catch((error) => {
                console.log("CheckRelevancePromocodeChina error:", error);
            })
            if(res.status) {
                if(res.status !== 200) {
                    resetVal(res.status + ': ' + res.statusText)
                } else {
                    if(res.data?.isError) {
                        resetVal(res.data?.ErrorText)
                    } else {
                        resetVal('', true, res.data.ResultData)
                    }
                }
            } else {
                if(res.response?.status !== 200) {
                    resetVal(res.response.status + ': ' + res.response.statusText)
                } else {
                    if(!res.response?.data || res.response?.data?.isError) {
                        resetVal(res.response.data?.ErrorText)
                    }
                }
            }
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        dispatch(setLoadSpinner(true))

        createPayment({
            parcels,
            promocode: promocodeIsValid ? promocode : '',
            promocodeData
        })
            .then((response) => {
                const div = document.createElement('div');
                document.body.appendChild(div)
                div.innerHTML = response.data;
                div.id = 'alipay';
                document.getElementById('alipaySubmit').setAttribute('target', '_blank')
                div.querySelector('form').submit()
                div.remove();

                hideModalForm(dispatch)();
                dispatch(setList(list.map(parcel => {
                    parcel.check = false;
                    return parcel;
                })))
            })
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    return (
        <Form noValidate onSubmit={handleSubmit} className={'mobile-payment-form'}>
            <div className="payment-header text-center">
                <img src="/assets/img/logo.svg" alt="Meest" className="mb-3" style={{width: '120px'}} />
            </div>

            <div className="payment-content">
                <div className="form-group-mobile mb-4">
                    <label className="title-label-mobile_form_lg mb-2">Parcel numbers:</label>
                    <div className="form-control-static">
                        {parcels.map((parcel, index) => (
                            <div key={index} className="d-flex justify-content-between mb-2">
                                <div className="d-flex">
                                    <span className="mr-2">{index + 1}.</span>
                                    <span>{parcel.code}</span>
                                </div>
                                <span>{parcel.sum}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="form-group-mobile mb-4">
                    <label className="title-label-mobile_form_lg mb-2">Total Pay:</label>
                    <div className="form-control-static total-pay">
                        {payTotal.toFixed(2)}
                    </div>
                </div>

                <div className="form-group-mobile mb-4">
                    <label className="title-label-mobile_form_lg mb-2">Promo code:</label>
                    <InputText
                        id={PROMOCODE}
                        placeholder={t('PromoCodeOnly')}
                        handleChange={(value) => {
                            setPromocode(value);
                            setPromocodeError('');
                            setPromocodeIsValid(false);
                            promocodeIsValid && calculate();
                        }}
                        handleBlur={(value) => setPromocode(value)}
                        errorMessage={promocodeError}
                        value={promocode}
                        validations={null}
                        errorStyles={promocodeError ? {
                            color: 'red',
                            fontSize: '12px',
                            marginTop: '4px'
                        } : {}}
                    />

                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            checkPromocode()
                        }}
                        className="btn btn-success w-100 mt-3 btn-mobile-form"
                        disabled={!promocode}
                    >
                        APPLY
                    </button>
                </div>

                <div className="mobile-form-actions">
                    <button
                        type="button"
                        onClick={() => hideModalForm(dispatch)()}
                        className="modal-form-button undefined btn btn-outline-primary w-100"
                    >
                        CANCEL
                    </button>
                    <button
                        type="submit"
                        disabled={promocode && !promocodeIsValid}
                        className="modal-form-button undefined btn btn-primary w-100"
                    >
                        PAY
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default PayParcelsMobileForm;
