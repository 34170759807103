import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import { setModalForm } from "../../storage/global";
import WarehouseForm from "../../components/forms/WarehouseForm";

const SendToWarehouseButton = ({className, isSelectedAll, classNameBtn=''}) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const list = useSelector(state => state.global.list);

    const count = useMemo(() => {
        return list.filter(parcel => parcel.check).length
    }, [list]);

    function send() {
        dispatch(setModalForm({
            title: t('send_to_wh'),
            form: <WarehouseForm count={count}/>,
            fullScreen: true,
        }))
    }

    return (
        <div className={`${className ? className : ''}`}>
            <Button
                disabled={!count}
                onClick={send}
                className={`btn-primary btnWarePaySelected ${count ? 'active' : ''} ${classNameBtn}`}
            >
                {t('send_to_wh')}
                {count > 0 && (
                    <span className="btn-counter">{count}</span>
                )}
            </Button>
        </div>
    );
};

export default SendToWarehouseButton;
