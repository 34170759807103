import React, { memo } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setModalForm } from "../../storage/global";
import { getList } from "../../utils/misc";

import SearchInput from "../../components/shared/SearchInput";
import DeleteButton from "../../components/shared/pages_buttons/DeleteButton";
import PrintStickerButton from "../../components/shared/pages_buttons/PrintStickerButton";
import SelectAllButton from "../../components/shared/pages_buttons/SelectAllButton";
import ButtonStyleAction from "../../components/shared/pages_buttons/ButtonStyleAction";
import EditButton from "../../components/shared/pages_buttons/EditButton";
import ExportButton from "../../components/shared/pages_buttons/ExportButton";
import FilterListBtn from "../../components/shared/pages_buttons/FilterListBtn";
import CreateNewParcel from "../../pages/unidentified_parcels/СreateNewParcel.jsx";
import NewParcelForm from "../../components/forms/NewParcelForm";

const ActionBlock = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleCreateNewParcel = React.useCallback(() => {
        dispatch(setModalForm({
            title: '',
            form: <NewParcelForm
                getListParcels={() => getList(dispatch, t)}
            />,
            fullScreen: true,
            hideOut: false,
        }));
    }, [dispatch, t]);

    return (
        <>
            <div className={'page-actions d-flex-mobile d-flex-mobile-none justify-content-between'}>
                <div className={'d-flex justify-content-start align-items-center'}>
                    <SelectAllButton items={props.rootItems} isSelectedAll={props.isSelectedAll} handle={props.handle} className={'mr-2'} classNameBtn={'w-170 p-4px-12px'}/>
                    <FilterListBtn classes={'py-4px'} className="w-170 mr-2" page={'waitingArrival'}/>
                    <SearchInput handleSearch={props.handleSearch} classes={'w-170 py-4px'} styles={{height: 'unset'}}/>
                </div>
                <div className={'d-flex justify-content-end align-items-center'}>
                    <ExportButton handle={props.handleExport} items={props.rootItems} className={'ml-5'} filename={props.exportFilename}/>
                    <EditButton handle={props.handleEdit} className={'ml-5'}/>
                    <DeleteButton handle={props.handleDelete} className={'ml-5'} deleteFormData={props.deleteFormData}>
                        <span>{t('remove_from_shipment')}</span>
                    </DeleteButton>
                    <PrintStickerButton handle={props.handlePrint} className={'ml-5'}/>
                    <ButtonStyleAction handle={props.openGroupSearch} className={'ml-5'} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
                </div>
            </div>
            <div className={'page-actions d-mobile'}>
                <div className={'d-flex justify-content-center align-items-center px-2'}>
                    <CreateNewParcel
                        className={'col-12 waiting-width'}
                        handle={handleCreateNewParcel}
                        classNameBtn="waiting_mobile"
                    />
                </div>
                <div className={'d-flex justify-content-between align-items-center px-2'}>
                    <SelectAllButton items={props.rootItems} isSelectedAll={props.isSelectedAll} handle={props.handle}
                                     className={'col-6'}/>
                    <SearchInput className={'col-6'} handleSearch={props.handleSearch}/>
                </div>
            </div>
        </>
    )
};

export default memo(ActionBlock);
