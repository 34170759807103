import React, { useMemo }       from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useTranslation }  from "react-i18next";
import { Button }          from "react-bootstrap";
import { trackEvent, AMPLITUDE_EVENTS } from "../../../utils/amplitudeTracker";

import { setModalForm, SET_KEY } from "../../../storage/global";
import ModalFilterOption from "../ModalFilterOption";
import CloseInputButton from "../CloseInputButton";
import {
    accountingFilters,
    newParcelsFilters,
    sentParcelsFilters,
} from "../../../utils/misc";
import {
    SEND_BY,
    OPERATION,
} from "../../../constants";

const FilterListBtn = ({className = '', page, isMobile = false, isHighBtn = false}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    function openFilterOption() {
        const eventType = page === 'newParcels'
            ? AMPLITUDE_EVENTS.FILTER_NEW_PARCELS
            : page === 'waitingArrival'
                ? AMPLITUDE_EVENTS.FILTER_WAITING_ARRIVAL
                : AMPLITUDE_EVENTS.FILTER_SENT_PARCELS;

        trackEvent(eventType, {
            pageSection: page,
            filterType: 'filter_button',
            source: 'filter_menu'
        });

        dispatch({type: SET_KEY, payload: {
                key: 'applyFilter',
                value: false,
            }});

        dispatch(setModalForm({
            title: t('Choose_filtering_variants'),
            form: <ModalFilterOption page={page} isMobile={isMobile}/>
        }));
    }
    const nothingSelectedFilter = {keyFilter: '', nameFilter: t('nothingSelected')}

    const applyFilter = useSelector(state => state.global.applyFilter);
    const filtersSelectedAccounting = useSelector(state => state.global.filtersSelectedAccounting);
    const filtersSelectedNewParcels = useSelector(state => state.global.filtersSelectedNewParcels);
    const filtersSelectedWaitingArrival = useSelector(state => state.global.filtersSelectedWaitingArrival);
    const filtersSelectedSentParcels = useSelector(state => state.global.filtersSelectedSentParcels);

    const filtersSelected = useMemo(() => {
        return page === 'accounting' ? filtersSelectedAccounting
            : page === 'newParcels' ? filtersSelectedNewParcels
            : page === 'waitingArrival' ? filtersSelectedWaitingArrival
            : filtersSelectedSentParcels
    }, [page, filtersSelectedAccounting, filtersSelectedNewParcels, filtersSelectedWaitingArrival, filtersSelectedSentParcels])

    const selectedFilterText = useMemo(() => {
        const filtersList = page === 'accounting' ? [
                nothingSelectedFilter,
                ...accountingFilters(t)]
        : [
            nothingSelectedFilter,
            ...newParcelsFilters(t),
            ...(page === 'sentParcels' ? sentParcelsFilters(t) : []),
        ];
        const filterSelectedExist = filtersList.find((el) => filtersSelected.length && el.keyFilter == filtersSelected[0].filterSelected)
        return filterSelectedExist?.nameFilter;
    }, [filtersSelected])

    function clearFilter(event) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({type: SET_KEY, payload: {
            key: 'applyFilter',
            value: false,
        }})
        dispatch({type: SET_KEY, payload: {
            key: 'filtersSelected',
            value: [],
        }})
    }

    return (
        <div className={`${className}`} style={{maxWidth: '220px'}}>
            <Button
                style={{
                    width: isMobile ? '100%' : '100%',
                    height: isMobile ? '32px' : isHighBtn ? '42px' : '34px',
                    display: 'flex',
                    alignItems: 'center',
                }}
                className={`position-relative ${isMobile ? '' : 'mr-2'} p-4px-5px text-500 text-left-important bor-rad-20px padding-when-clear-search-button-viewed`}
                onClick={openFilterOption}
                type={'button'}
                variant={'outline-primary'}
            >
                <img className={'mr-1'} src="/assets/img/filter.png" alt="export-button" height={isMobile ? '24px' : isHighBtn ? '34px' : "26px"}/>
                <span
                    style={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >   {t('Filter')+(isMobile || !applyFilter ? '' : ': '+selectedFilterText)}
                </span>
                <CloseInputButton clear={clearFilter}
                                  closeButton={applyFilter}
                                  classNames={page === 'accounting' ? 'top-7px' : 'top-3px'}
                />
            </Button>
        </div>
    );
};

export default FilterListBtn;
