import React, { memo } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalForm } from "../../storage/global";
import { getList } from "../../utils/misc";

import SendToWarehouseButton from "./SendToWarehouseButton";
import SearchInput from "../../components/shared/SearchInput";
import FilterListBtn from "../../components/shared/pages_buttons/FilterListBtn";
import EditButton from "../../components/shared/pages_buttons/EditButton";
import DeleteButton from "../../components/shared/pages_buttons/DeleteButton";
import PrintStickerButton from "../../components/shared/pages_buttons/PrintStickerButton";
import ExportButton from "../../components/shared/pages_buttons/ExportButton";
import ButtonStyleAction from "../../components/shared/pages_buttons/ButtonStyleAction";
import SelectAllButton from "../../components/shared/pages_buttons/SelectAllButton";
import CreateNewParcel from "../../pages/unidentified_parcels/СreateNewParcel.jsx";
import NewParcelForm from "../../components/forms/NewParcelForm";

const ActionBlock = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleCreateNewParcel = React.useCallback(() => {
        dispatch(setModalForm({
            title: '',
            form: <NewParcelForm
                getListParcels={() => getList(dispatch, t)}
            />,
            fullScreen: true,
            hideOut: false,
        }));
    }, [dispatch, t]);

    return (
        <>
            <div className={'page-actions d-flex-mobile d-flex-mobile-none justify-content-between'}>
                <div className={'d-flex justify-content-start align-items-center'}>
                    <div className="">
                        <SelectAllButton isSelectedAll={props.isSelectedAll} handle={props.handle}
                                         className={'mr-2 mb-2 full-width'} classNameBtn={'p-4px-12px'}/>
                        <SendToWarehouseButton isSelectedAll={props.isSelectedAll} className={'mr-2'}
                                               classNameBtn={'p-4px-12px ws-nowrap'}/>
                    </div>
                    <div className="">
                        <FilterListBtn classNameBtn={'w-100'} className="mb-2" page={'newParcels'}/>
                        <SearchInput
                            handleSearch={props.handleSearch}
                            classes={'py-4px wunset'}
                            styles={{height: 'unset'}}
                        />
                    </div>
                </div>
                <div className={'d-flex justify-content-end align-items-center'}>
                    <EditButton handle={props.handleEdit} className={'ml-5'}/>
                    <DeleteButton handle={props.handleDelete} className={'ml-5'} deleteFormData={props.deleteFormData}/>
                    <PrintStickerButton handle={props.handlePrint} className={'ml-5'} checkProcessed={true}/>
                    <ExportButton handle={props.handleExport} items={props.rootItems} className={'ml-5'}
                                  filename={props.exportFilename}/>
                    <ButtonStyleAction handle={props.openGroupSearch} className={'ml-5'} text={'GroupSearch'}
                                       iconClasses={'fa-search mr-1'}/>
                </div>
            </div>
            <div className={'page-actions d-mobile'}>
                <div className={'d-flex justify-content-between align-items-center px-2 mb-3'}>
                    <SelectAllButton isSelectedAll={props.isSelectedAll} handle={props.handle} items={props.rootItems}
                                     className={'col-6'}/>
                    <CreateNewParcel
                        className={'col-6 new-parcel-width'}
                        handle={handleCreateNewParcel}
                    />
                </div>
                <SearchInput className={'col-12 px-2'} handleSearch={props.handleSearch}/>
                <div>
                    <SendToWarehouseButton className={'d-grid px-2 pt-3 w-100'}/>
                </div>
            </div>
        </>
    )
};


export default memo(ActionBlock);
