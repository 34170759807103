
import { CONSOLE, SEND_BY } from "../constants";
import { getStatus } from "../utils/misc";

const RESET_STATE = 'RESET_STATE'
const SET_LOAD = 'SET_LOAD'
const SET_IS_MOBILE = 'SET_IS_MOBILE'
const SET_SEARCH = 'SET_SEARCH'
const SET_LOAD_SPINNER = 'SET_LOAD_SPINNER'
const SET_LOCALE = 'SET_LOCALE'
const SET_MODAL_FORM_SHOW = 'SET_MODAL_FORM_SHOW'
const SET_MODAL_FORM_2_SHOW = 'SET_MODAL_FORM_2_SHOW'
const SET_GROUP_SEARCHES_CHECKBOXES = 'SET_GROUP_SEARCHES_CHECKBOXES'
const SET_MENU_SHOW = 'SET_MENU_SHOW'
const SET_ALERT = 'SET_ALERT'
const SET_LIST = 'SET_LIST'
const SET_ALERT_SHOW = 'SET_ALERT_SHOW'
const SET_ALERT_TITLE = 'SET_ALERT_TITLE'
const SET_ALERT_TEXT = 'SET_ALERT_TEXT'
const SET_ALERT_TYPE = 'SET_ALERT_TYPE'
const SET_ALERT_CLEAR = 'SET_ALERT_CHILDREN'
const SET_PROFILE = 'SET_PROFILE'
const SET_BALANCE = 'SET_BALANCE'
const SET_BALANCE_FREE = 'SET_BALANCE_FREE'
const SET_INSURANCE_COUNTRES = 'SET_INSURANCE_COUNTRES'
const SET_LOST_PARSELS = 'SET_LOST_PARSELS'
const SET_RULES = 'SET_RULES'
const SET_LOST_SELECT_FROM_OUT = 'SET_LOST_SELECT_FROM_OUT'
const SET_LIST_PROPERTIES_SELECT_FROM_OUT = 'SET_LIST_PROPERTIES_SELECT_FROM_OUT'
const SET_BALANCE_NOT_PAID = 'SET_BALANCE_NOT_PAID'
const SET_SELECTED_PARCEL_LIST = 'SET_SELECTED_PARCEL_LIST'
const ADD_SELECTED_PARCEL_LIST = 'ADD_SELECTED_PARCEL_LIST'
const REMOVE_SELECTED_PARCEL_LIST = 'REMOVE_SELECTED_PARCEL_LIST'
const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST'
const SET_TRACKER_LIST = 'SET_TRACKER_LIST'
const ADD_TRACKER = 'ADD_TRACKER'
const TOKEN_INFO = 'TOKEN_INFO'
const CURRENT_PAGE = 'CURRENT_PAGE'
const SET_STATUSES = 'SET_STATUSES'
const SET_TRACKERS = 'SET_TRACKERS'
const SET_RESPONSIBLE_EMAILS = 'SET_RESPONSIBLE_EMAILS'
const CREATE_RESPONSIBLE_EMAIL = 'CREATE_RESPONSIBLE_EMAIL'
const EDIT_RESPONSIBLE_EMAIL = 'EDIT_RESPONSIBLE_EMAIL'
const SET_POLL = 'SET_POLL'
const RESTORE_PASSWORD_DATA = 'RESTORE_PASSWORD_DATA'
export const SET_NEWS_PARAMS = 'SET_NEWS_PARAMS'
export const SET_NEWS_STOP_CAROUSEL = 'SET_NEWS_STOP_CAROUSEL'
export const SET_NEWS = 'SET_NEWS'
export const RECEIVERS = 'RECEIVERS'
export const SET_LOAD_SPINNER_RACCOON = 'SET_LOAD_SPINNER_RACCOON'
export const SET_FEEDBACK_TEXT = 'SET_FEEDBACK_TEXT'
export const SET_SENDING_FEEDBACK = 'SET_SENDING_FEEDBACK'
export const SET_KEY = 'SET_KEY'

const defRestorePasswordData = {
    contactIsPhone: false,
    connection_id: '',
    code: ''
}

export const defHeadNotificationBarSettings = {
    isActive: false,
    notificationBarText: '',
    notificationBarTextLink: '',
    popUpText: '',
    btnText: '',
    btnLink: '',
    cn_notificationBarText: '',
    cn_notificationBarTextLink: '',
    cn_popUpText: '',
    cn_btnText: '',
    cn_btnLink: '',
}

export const defModalForm = {
    show: false,
    title: '',
    form: null,
    fullScreen: false,
    hideOut: true,
    styles: null,
    formContent: null,
    formBody: {classNames: ''},
    hideCloseButton: false,
    withoutClose: false,
}

const defaultState = {
    profile: {
        currency: "",
        ic_crd_num: "",
        company_name: "",
        contact_name: "",
        id: 0,
        phone: "",
        sign: "",
        user_type: null,
        usercode: null,
        username: "",
        useruid: "",
        wechat: "",
    },
    isAgent: false,
    list: [],
    search: '',
    balance: '',
    balanceNotPaid: {
        count: 0,
        debt: 0,
        parcels: []
    },
    balance_free: '',
    insuranceCountries: [],
    load: true,
    spinner: false,
    spinnerRaccoon: false,
    feedbackText: '',
    sendingFeedback: false,
    locale: 'en',
    modalForm: defModalForm,
    modalForm2: defModalForm,
    showMenu: false,
    alert: {
        show: false,
        title: '',
        text: '',
        type: '',
        withoutCancel: false,
        children: null,
        withoutIcon: false,
    },
    groupSearchesCheckboxes: {
      withGroupFilter: false,
      newParcels: {},
      waitingArrival: {},
      sentParcels: {},
      accounting: {}
    },
    currentPage: '',
    countryList: [],
    europeCountries: [],
    countries: [],
    selectedList: [],
    trackerList: {},
    tokenInfo: {
        token: '',
        refreshToken: '',
        expireIn: 0,
        expireTimestamp: 0,
        expireDate: ''
    },
    statuses: [],
    lastTrackingStatuses: {},
    responsibleEmails: [],
    poll: {},
    lost: {
        list: [],
        selectFromOut: ''
    },
    rules: [],
    listProperties: {
        selectFromOut: []
    },
    promoCodesList: [],
    restorePasswordData: defRestorePasswordData,
    receivers: [],
    newsCountRoot: '',
    newShowTimeRoot: 3,
    newsUpdateTime: '',
    newsStopCarousel: false,
    news: [],
    fakeDoorUserId: '',
    showFakeDoor: false,
    headNotificationBarSettings: defHeadNotificationBarSettings,
    fakeDoorsIcon: {},
    fakeDoorsModalBG: {},
    qrCode: {
        qr_code: ""
    },
    closeNotificationForBirthday: false,
    applyFilter: false,
    filtersSelectedAccounting: [],
    filtersSelectedNewParcels: [],
    filtersSelectedSentParcels: [],
    filtersSelectedWaitingArrival: [],
    isMobile: window.innerWidth < 1200
}

function mappingProfileData(data) {
    return {
        currency: data.currency ?? '',
        company_name: data.company_name ?? '',
        ic_crd_num: data.ic_crd_num ?? '',
        id: data.id ?? '',
        contact_name: data.contact_name ?? '',
        phone: data.phone ?? '',
        sign: data.sign ?? '',
        user_type: data.user_type ? Number(data.user_type) : null,
        usercode: data.usercode ?? '',
        username: data.username ?? '',
        useruid: data.useruid ?? '',
        wechat: data.wechat ?? '',
        birthday: data.birthday ?? '',
    }
}

export default function globalReducer(state = defaultState, action) {
    switch (action.type) {
        case RESET_STATE:
            return action.payload
        case SET_LOAD:
            return {
                ...state,
                load: action.payload
            }
        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload
            }
        case SET_LOAD_SPINNER:
            return {
                ...state,
                spinner: action.payload
            }
        case SET_LOAD_SPINNER_RACCOON:
            return {
                ...state,
                spinnerRaccoon: action.payload
            }
        case SET_FEEDBACK_TEXT:
            return {
                ...state,
                feedbackText: action.payload
            }
        case SET_SENDING_FEEDBACK:
            return {
                ...state,
                sendingFeedback: action.payload
            }
        case SET_LOCALE:
            return {
                ...state,
                locale: action.payload
            }
        case SET_LIST: {
            const newList = addTrackerToLIst([...action.payload.list], state.lastTrackingStatuses, action.payload.noStatus, state.statuses)
            return {
                ...state,
                list: newList
            }
        }
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload
            }
        case SET_MODAL_FORM_SHOW:
            return {
                ...state,
                modalForm: {
                    ...state.modalForm,
                    ...action.payload,
                    fullScreen: action.payload.fullScreen !== undefined ? action.payload.fullScreen : false,
                    show: action.payload.show !== undefined ? action.payload.show : true,
                    styles: action.payload.styles ?? null,
                    formContent: action.payload.formContent ?? null,
                    formBody: action.payload.formBody ?? {classNames: ''},
                    hideCloseButton: !!action.payload.hideCloseButton
                }
            }
        case SET_MODAL_FORM_2_SHOW:
            return {
                ...state,
                modalForm2: {
                    ...state.modalForm2,
                    ...action.payload,
                    fullScreen: action.payload.fullScreen !== undefined ? action.payload.fullScreen : false,
                    show: action.payload.show !== undefined ? action.payload.show : true,
                    styles: action.payload.styles ?? null,
                    formContent: action.payload.formContent ?? null,
                    formBody: action.payload.formBody ?? {classNames: ''},
                    hideCloseButton: !!action.payload.hideCloseButton
                }
            }
        case SET_GROUP_SEARCHES_CHECKBOXES:
            return {
                ...state,
                groupSearchesCheckboxes: {
                    ...state.groupSearchesCheckboxes,
                    [action.payload.page]: action.payload.val
                }
            }
        case SET_ALERT: {
            return {
                ...state,
                alert: {
                    ...action.payload,
                    show: action.payload.show !== undefined ? action.payload.show : true,
                    title: action.payload?.title ? action.payload.title : 'Message',
                }
            }
        }
        case SET_ALERT_SHOW:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    show: action.payload
                }
            }
        case SET_ALERT_TITLE:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    title: action.payload
                }
            }
        case SET_ALERT_TEXT:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    text: action.payload
                }
            }
        case SET_ALERT_TYPE:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    type: action.payload
                }
            }
        case SET_ALERT_CLEAR:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    withoutCancel: false,
                    children: null,
                    withoutIcon: false,
                }
            }
        case SET_MENU_SHOW:
            return {
                ...state,
                showMenu: action.payload
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: mappingProfileData(action.payload),
                isAgent: mappingProfileData(action.payload).user_type === CONSOLE
            }
        case SET_BALANCE:
            return {
                ...state,
                balance: action.payload
            }
        case SET_BALANCE_FREE:
            return {
                ...state,
                balance_free: action.payload
            }
        case SET_INSURANCE_COUNTRES:
            return {
                ...state,
                insuranceCountries: action.payload
            }
        case SET_LOST_PARSELS: {
            return {
                ...state,
                lost: {
                    ...state.lost,
                    list: action.payload
                }
            }
        }
        case SET_RULES: {
            return {
                ...state,
                rules: action.payload
            }
        }
        case SET_LOST_SELECT_FROM_OUT: {
            return {
                ...state,
                lost: {
                    ...state.lost,
                    selectFromOut: action.payload
                }
            }
        }
        case SET_LIST_PROPERTIES_SELECT_FROM_OUT: {
            return {
                ...state,
                listProperties: {
                    ...state.listProperties,
                    selectFromOut: action.payload
                }
            }
        }
        case SET_BALANCE_NOT_PAID:
            return {
                ...state,
                balanceNotPaid: action.payload
            }
        case SET_SELECTED_PARCEL_LIST:
            return {
                ...state,
                selectedList: action.payload
            }
        case ADD_SELECTED_PARCEL_LIST:
            return {
                ...state,
                selectedList: state.selectedList.concat(action.payload)
            }
        case REMOVE_SELECTED_PARCEL_LIST:
            return {
                ...state,
                selectedList: state.selectedList.filter(item => item.uid !== action.payload.uid)
            }
        case SET_COUNTRY_LIST: {
            const eurList = action.payload.filter( el => +el.isEurope);
            const countriesLimitation = {};
            action.payload.forEach(c => {
                if (!countriesLimitation[c.country_id]) {
                    countriesLimitation[c.country_id] = {[c.delivery_type]: {[c.danger_type]: c}};
                } else {
                    if (!countriesLimitation[c.country_id][c.delivery_type]) {
                        countriesLimitation[c.country_id][c.delivery_type] = {[c.danger_type]: c};
                    } else {
                        countriesLimitation[c.country_id][c.delivery_type][c.danger_type] = c;
                    }
                }
            })
            
            return {
                ...state,
                countryList: action.payload,
                europeCountries: eurList.length ? eurList.map(el=>el.country_id) : [],
                countries: countriesLimitation
            }
        }
        case SET_NEWS_PARAMS: {
            return {
                ...state,
                newsCountRoot: action.payload.newsCount,
                newShowTimeRoot: action.payload.newShowTime || 3,
            }
        }
        case SET_NEWS: {
            return {
                ...state,
                newsUpdateTime: action.payload.newsTime,
                news: JSON.parse(action.payload.newsData),
            }
        }
        case SET_NEWS_STOP_CAROUSEL: {
            return {
                ...state,
                newsStopCarousel: action.payload,
            }
        }
        case SET_TRACKER_LIST:
            return {
                ...state,
                trackerList: action.payload
            }
        case ADD_TRACKER:
            // state.trackerList.concat(action.payload).map(v => list[v.barCode] = v)
            return {
                ...state,
                trackerList: {...state.trackerList, ...action.payload}
            }
        case TOKEN_INFO:
            return {
                ...state,
                tokenInfo: {
                    ...state.tokenInfo,
                    ...action.payload,
                }
            }
        case CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case SET_STATUSES:
            return {
                ...state,
                statuses: action.payload
            }
        case SET_TRACKERS: {
            const newTrackers = {...state.lastTrackingStatuses};
            const newData = action.payload.trackers;
            Object.keys(newData).forEach( (shipment) => {
                newTrackers[shipment] = newData[shipment];
            })
            const newList = addTrackerToLIst([...state.list], newTrackers, action.payload.noStatus, state.statuses)
            return {
                ...state,
                list: newList,
                lastTrackingStatuses: newTrackers
            }
        }
        case SET_RESPONSIBLE_EMAILS: {
            return {
                ...state,
                responsibleEmails: action.payload
            }
        }
        case CREATE_RESPONSIBLE_EMAIL: {
            const newEmailsList = [...state.responsibleEmails];
            const indexFindEl = newEmailsList.findIndex( emailItem => emailItem.email === '' &&  emailItem.newValue === action.payload)
            if (indexFindEl >= 0) {
                newEmailsList[indexFindEl].email = action.payload
                newEmailsList[indexFindEl].isEdit = false
            }
            return {
                ...state,
                responsibleEmails: newEmailsList
            }
        }
        case EDIT_RESPONSIBLE_EMAIL: {
            const newEmailsList = [...state.responsibleEmails];
            const indexFindEl = newEmailsList.findIndex( emailItem => emailItem.email === action.payload.oldResEmail)
            if (indexFindEl >= 0) {
                newEmailsList[indexFindEl].email = action.payload.newResEmail
                newEmailsList[indexFindEl].isEdit = false
            }
            return {
                ...state,
                responsibleEmails: newEmailsList
            }
        }
        case SET_POLL: {
            return {
                ...state,
                poll: action.payload
            }
        }
        case RESTORE_PASSWORD_DATA: {
            return {
                ...state,
                restorePasswordData: action.payload
            }
        }
        case RECEIVERS: {
            const newReceivers = action.payload.sort( (a, b) => {
                const nameA = a.reciver.toUpperCase();
                const nameB = b.reciver.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              }).map( (receiver) => {
                return {...receiver, label: receiver.reciver + ' ' + receiver.tel}
            });
            return {
                ...state,
                receivers: newReceivers
            }
        }
        case SET_KEY: {
            const {key, value} = action.payload;
            return {
                ...state,
                [key]: value
            }
        }

        default:
            return state;
    }
}

export const resetState = () => ({type:RESET_STATE, payload: defaultState})
export const setList = (list, noStatus) => ({type:SET_LIST, payload: {list, noStatus}})
export const setSearch = (search) => ({type:SET_SEARCH, payload: search})
export const setLoad = (load) => ({type:SET_LOAD, payload: load})
export const setIsMobile = (mobile) => ({type:SET_IS_MOBILE, payload: mobile})
export const setLoadSpinner = (spinner) => ({type:SET_LOAD_SPINNER, payload: spinner})
export const setLocale = (locale) => ({type: SET_LOCALE, payload: locale})
export const setModalForm = (modalForm) => ({type: SET_MODAL_FORM_SHOW, payload: modalForm})
export const setModalForm2 = (modalForm) => ({type: SET_MODAL_FORM_2_SHOW, payload: modalForm})
export const setGroupSearchesCheckboxes = (listAndType) => ({type: SET_GROUP_SEARCHES_CHECKBOXES, payload: listAndType})
export const setAlert = (alert) => ({type: SET_ALERT, payload: alert})
export const setAlertShow = (show) => ({type: SET_ALERT_SHOW, payload: show})
export const setAlertTitle = (title) => ({type: SET_ALERT_TITLE, payload: title})
export const setAlertText = (text) => ({type: SET_ALERT_TEXT, payload: text})
export const setAlertType = (type) => ({type: SET_ALERT_TYPE, payload: type})
export const setAlertClear = (type) => ({type: SET_ALERT_CLEAR})
export const setShowMenu = (show) => ({type: SET_MENU_SHOW, payload: show})
export const setProfile = (data) => ({type: SET_PROFILE, payload: data})
export const setBalance = (balance) => ({type: SET_BALANCE, payload: balance})
export const setSelectedList = (selected) => ({type: SET_SELECTED_PARCEL_LIST, payload: selected})
export const addSelectedParcelList = (item) => ({type: ADD_SELECTED_PARCEL_LIST, payload: item})
export const removeSelectedParcelList = (item) => ({type: REMOVE_SELECTED_PARCEL_LIST, payload: item})
export const setCountryList = (list) => ({type: SET_COUNTRY_LIST, payload: list})
export const setTrackerList = (list) => ({type: SET_TRACKER_LIST, payload: list})
export const addTracker = (tracker) => ({type: ADD_TRACKER, payload: tracker})
export const setTokenInfo = (tokenInfo) => ({type: TOKEN_INFO, payload: tokenInfo})
export const setCurrentPage = (page) => ({type: CURRENT_PAGE, payload: page})
export const setBalanceNotPaid = (balance) => ({type: SET_BALANCE_NOT_PAID, payload: balance})
export const setBalanceFree = (balance) => ({type: SET_BALANCE_FREE, payload: balance})
export const setInsuranceCountres = (balance) => ({type: SET_INSURANCE_COUNTRES, payload: balance})
export const setlostParsels = (lostParsels) => ({type: SET_LOST_PARSELS, payload: lostParsels})
export const setRules = (rules) => ({type: SET_RULES, payload: rules})
export const setSelectFromOut = (TrackNumber) => ({type: SET_LOST_SELECT_FROM_OUT, payload: TrackNumber})
export const setListPropertiesSelectFromOut = (TrackNumber) => ({type: SET_LIST_PROPERTIES_SELECT_FROM_OUT, payload: TrackNumber})
export const setStatuses = (statuses) => ({type: SET_STATUSES, payload: statuses})
export const setTrackers = (trackers, noStatus) => ({type: SET_TRACKERS, payload: {trackers, noStatus}})
export const setResponsibleEmails = (responsibleEmails) => ({type: SET_RESPONSIBLE_EMAILS, payload: responsibleEmails})
export const setNewResponsibleEmail = (payload) => ({type: CREATE_RESPONSIBLE_EMAIL, payload})
export const updateResponsibleEmail = (payload) => ({type: EDIT_RESPONSIBLE_EMAIL, payload})
export const setPoll = (payload) => ({type: SET_POLL, payload})
export const setRestorePasswordData = (payload) => ({type: RESTORE_PASSWORD_DATA, payload})
export const clearRestorePasswordData = () => ({type: RESTORE_PASSWORD_DATA, payload: defRestorePasswordData})

function addTrackerToLIst(list, lastTrackingStatuses_, noStatus, statuses) {
    if (!list || !list.length) return list;
    // функція реагує лише на повну зміну даних чи зміну статусів,
    // у всіх інших викликах цієї функції (наприклад зміна чекбоксів) noStatus не передається
    if (!noStatus ) return list;
    const newList = list;
    for (let index = 0; index < list.length; index++) {
        const parcel = list[index];
        const { code } = parcel;

        const status_text = lastTrackingStatuses_ &&
            lastTrackingStatuses_[code] &&
            lastTrackingStatuses_[code].laststatus ?
            getStatus(statuses, lastTrackingStatuses_[code].laststatus.status) : noStatus;
        newList[index].tracker = status_text;
    }
    return newList
}
