import React from 'react';
import { useTranslation } from "react-i18next";

import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";

const CreateNewParcel = ({
                             className = '',
                             handle,
                             classNameBtn = '',
                             classNameImg = 'mr-1'
                         }) => {
    const { t, i18n } = useTranslation();

    const buttonText = React.useMemo(() => {
        return i18n.language === 'cn' ? '创建新包裹' : 'Create new parcel';
    }, [i18n.language]);

    const onClick = React.useCallback((e) => {
        e.preventDefault();
        trackEvent(AMPLITUDE_EVENTS.ADD_NEW_NEW_PARCELS, {
            pageSection: 'new_parcels',
            source: 'create_button'
        });
        if (typeof handle === 'function') {
            handle();
        }
    }, [handle]);

    return (
        <button
            onClick={onClick}
            type="button"
            className={`p-0 p-md-1 create-new-parcel-btn btn btn-outline-primary-no-border ${className}`}
        >
            <span>{buttonText}</span>
            <svg className="ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none"/>
                <path d="M12 7L12 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                <path d="M7 12L17 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </button>
    );
};

export default CreateNewParcel;
