import React, { memo } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setModalForm } from "../../storage/global";
import { getList } from "../../utils/misc";

import SearchInput from "../../components/shared/SearchInput";
import MyDatePicker from "../../components/shared/datepicker/MyDatePicker";
import FilterListBtn from "../../components/shared/pages_buttons/FilterListBtn";
import ExportButton from "../../components/shared/pages_buttons/ExportButton";
import PaySelectedButton from "../../components/shared/pages_buttons/PaySelectedButton";
import SelectNotPayParcelButton from "../../components/shared/pages_buttons/SelectNotPayParcelButton";
import ButtonStyleAction from "../../components/shared/pages_buttons/ButtonStyleAction";
import PrintStickerButton from "../../components/shared/pages_buttons/PrintStickerButton";
import NewParcelForm from "../../components/forms/NewParcelForm";
import CreateNewParcel from "../../pages/unidentified_parcels/СreateNewParcel.jsx";

const ActionBlock = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.global.isMobile);

    const handleCreateNewParcel = React.useCallback(() => {
        dispatch(setModalForm({
            title: '',
            form: <NewParcelForm
                getListParcels={() => getList(dispatch, t)}
            />,
            fullScreen: true,
            hideOut: false,
        }));
    }, [dispatch, t]);

    return (
        <>
            {isMobile ? (
                <div className={'page-actions d-mobile'}>
                    <div className={'d-flex justify-content-center align-items-center p-2 gap10 mobile-version'}>
                        <CreateNewParcel
                            className={'col-12 mb-create-new-parcel-btn'}
                            handle={handleCreateNewParcel}
                            classNameBtn="sparcel_mobile"
                        />
                    </div>
                    <div className={'d-flex justify-content-between align-items-center p-2 gap10 mobile-version'}>
                        <SelectNotPayParcelButton classNameBtn={'mw-100pr'} handle={props.handleSelectedToPay}/>
                        <PaySelectedButton handle={props.handlePay} selectedList={props.selectedList}/>
                    </div>
                    <div className={'p-2'}>
                        <SearchInput className={'mw-100pr mb-3'} handleSearch={props.handleSearch}/>
                    </div>
                    <div className={'d-flex justify-content-between align-items-center p-2'}>
                        <MyDatePicker
                            handle={props.handleDatePicker}
                            startEnd
                            className={'d-flex'}
                            classNameFirstDate={'px-2'}
                            classNameSecondDate={'px-2'}
                            colFirst={['12', 'auto']}
                            colSecond={['12', 'auto']}
                            textFirst={t('date_from')}
                            textSecond={t('date_to')}
                        />
                    </div>
                </div>
            ) : (
                <div className={'page-actions d-flex-mobile d-flex-mobile-none justify-content-between'}>
                    <div className={'d-flex justify-content-start align-items-center mr-3 ws-nowrap'}>
                        <div className="">
                            <SelectNotPayParcelButton handle={props.handleSelectedToPay} className={'mr-2 mb-2'} classNameBtn={'mw-100pr p-4px-12px ws-nowrap'}/>
                            <PaySelectedButton handle={props.handlePay} selectedList={props.selectedList} className={'mr-2'} classNameBtn={'p-4px-12px ws-nowrap'}/>
                        </div>
                        <div className="">
                            <FilterListBtn classNameBtn={'mw-100pr p-4px-12px ws-nowrap'} page={'sentParcels'} className="mb-2"/>
                            <SearchInput className={"minw-150"} handleSearch={props.handleSearch} classes={'py-4px wunset'} styles={{height: 'unset'}}/>
                        </div>
                    </div>
                    <div className={'d-flex justify-content-end align-items-center'}>
                        <MyDatePicker
                            handle={props.handleDatePicker}
                            startEnd
                            className={'d-flex'}
                            stylesFirstDate={{marginRight: '15px'}}
                            classNameclassName={'d-flex'}
                            stylesFirstDate={{marginRight: '15px'}}
                            classNameFirstDate={'mr-3'}
                            textFirst={t('date_from')}
                            textSecond={t('date_to')}
                            colFirst={['auto']}
                            colSecond={['auto']}
                            TitleCClassNames={"pl-15 "}
                            TitleClassNames={"lh-0"}
                            DPCClassNames={"mtb-9 minw-105 w-100px col"}
                        />
                        <ExportButton handle={props.handleExport} items={props.rootItems} className={'ml-5'} buttClassNames={""} filename={props.exportFilename}/>
                        <PrintStickerButton handle={props.handlePrint} className={'ml-5'}/>
                        <ButtonStyleAction handle={props.openGroupSearch} className={'ml-5'} buttClassNames={""} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
                    </div>
                </div>
            )}
        </>
    )
};

export default memo(ActionBlock);
