import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";

import {
    setAlertShow,
    setCurrentPage,
    setList,
    setLoadSpinner,
    setModalForm,
    setSearch,
    setSelectedList,
    setTokenInfo,
    setTrackerList,
    setGroupSearchesCheckboxes,
    setListPropertiesSelectFromOut,
    SET_KEY,
} from "../../storage/global";

import {
    CHINA_TRACK, CODE,
    COMMODITY,
    ESTIMATED_DELIVERY,
    CONSIGNEE,
    DATE,
    DESC,
    QTY, routesNames, SELECTED,
    SEND_BY, SUM_2,
    TO_COUNTRY,
    TRACK_NUMBER, TRACKER,
    SERVICE_TYPE,
    WEIGHT
} from "../../constants";

import ActionBlock from "./ActionBlock";
import moment from "moment/moment";
import CheckBox from "../../components/input/CheckBox";
import PayParcelsForm from "../../components/forms/PayParcelsForm";
import PrintSettingsForm from "../../components/forms/PrintSettingsForm";
import SentParcelDetailForm from "../../components/forms/SentParcelDetailForm";
import PageListTemplate from "../../components/shared/PageListTemplate";
import PageMenu from "../../components/shared/PageMenu";
import CheckboxList from "../../components/shared/CheckboxList";
import ExportButton from "../../components/shared/pages_buttons/ExportButton";
import FilterListBtn from "../../components/shared/pages_buttons/FilterListBtn";
import ButtonStyleAction from "../../components/shared/pages_buttons/ButtonStyleAction";
import PrintStickerButton from "../../components/shared/pages_buttons/PrintStickerButton";

import { exportSentParcels, getSentParcels, getTrackers } from "../../utils/api";
import { goTrackingSystem, viewAlert } from "../../utils/misc";

const SentParcels = () => {

    const order = {
        direction: DESC,
        name: DATE
    };

    const date = moment();

    const filename = `sent_parcels_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;

    const {t, i18n} = useTranslation();

    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list);
    const groupSearchesCheckboxes = useSelector(state => state.global.groupSearchesCheckboxes.sentParcels);
    const withGroupFilter = useSelector(state => state.global.groupSearchesCheckboxes.withGroupFilter);
    const selectFromOut = useSelector(state => state.global.listProperties.selectFromOut);

    const [checkedAll, setCheckedAll] = useState(false);
    const [listAfterFilter, setListAfterFilter] = useState([]);

    useEffect(() => {
        dispatch(setCurrentPage(routesNames.SENT_PARCELS))
        return () => {
            dispatch(setModalForm({show: false}))
            dispatch(setAlertShow(false))
            dispatch(setSearch(''))
            dispatch(setList([]))
            dispatch(setSelectedList([]))
            dispatch(setTrackerList([]))
            dispatch({type: SET_KEY, payload: {
                    key: 'applyFilter',
                    value: false,
                }})
        }
    }, [])

    useEffect(() => {
        if(selectFromOut && selectFromOut.length && list.length && list[0].list_type === 'sent-parcels') {
            dispatch(setList(list.map(parcel => {
                if (parcel.is_paid === "false") parcel.check = true;
                else parcel.check = false;
                return parcel;
            })));
            dispatch(setListPropertiesSelectFromOut([]));
            dispatch(setModalForm({
                title: t('pay').slice(0, 1).toUpperCase() + t('pay').slice(1),
                form: <PayParcelsForm parcels={selectFromOut}/>,
                fullScreen: true
            }))
        }
    }, [list, selectFromOut])

    const groupSearchesCheckboxesCodes = useMemo(() => Object.keys(groupSearchesCheckboxes).filter(code => groupSearchesCheckboxes[code]), [groupSearchesCheckboxes])

    function orderedHook(parcels) {
        if (parcels && parcels.length) {
            const trackersFromLocalStorageJSON = localStorage.getItem('trackers');
            let trackers = {};
            let expire = 0;
            const currentTimestamp = new Date().getTime();
            if (trackersFromLocalStorageJSON) {
                const trackersFromLocalStorage = JSON.parse(trackersFromLocalStorageJSON)
                if (trackersFromLocalStorage) {
                    expire = Number(trackersFromLocalStorage.expire);
                    const isExpire = currentTimestamp > expire
                    if (!isExpire) {
                        trackers = {...trackersFromLocalStorage.list};
                    }
                }
            }

            const loadCodes = [];
            for (let index = 0; index < parcels.length; index++) {
                const parcel = parcels[index];

                if (!trackers[parcel.code] || trackers[parcel.code].eventDescr_descrEN === "No status") {
                    loadCodes.push(parcel.code);
                }
            }
            loadCodes.length && getTrackers(loadCodes, dispatch, t('noStatus'));
        }
    }

    function handleSearch() {
        dispatch(setSelectedList([]));
    }

    function handleExport() {
        const sendList = list.filter(parcel => parcel.check);
        trackEvent(AMPLITUDE_EVENTS.EXPORT_SENT_PARCELS, {
            pageSection: 'sent_parcels',
            itemsCount: sendList.length
        });

        sendList.forEach(el => {
            let service_type = el.service_type;
            if(el.service_type === "0") service_type = 'TO DOOR';
            if(el.service_type === "1") service_type = 'PUDO';
            el.service_type = service_type;
        });
        return exportSentParcels(sendList);
    }

    function openGroupSearch() {
        trackEvent(AMPLITUDE_EVENTS.FILTER_SENT_PARCELS, {
            pageSection: 'sent_parcels',
            filterType: 'group_search'
        });
        dispatch(setModalForm({
            title: t('GroupSearchMenu'),
            form: <CheckboxList list={groupSearchesCheckboxes} setList={saveAndApplyGroupSearch}/>
        }));
    }
    function saveAndApplyGroupSearch(list) {
        dispatch(setGroupSearchesCheckboxes({
            page: 'sentParcels',
            val: list
        }))
    }

    function handleDatePicker(dateObject) {
        trackEvent(AMPLITUDE_EVENTS.FILTER_SENT_PARCELS, {
            pageSection: 'sent_parcels',
            filterType: 'date_range',
            dateRange: {
                start: dateObject.firstDate.format('YYYY.MM.DD'),
                end: dateObject.secondDate.format('YYYY.MM.DD')
            }
        });

        dispatch(setLoadSpinner(true));

        const date = {
            start: dateObject.firstDate.format('YYYY.MM.DD'),
            end: dateObject.secondDate.format('YYYY.MM.DD'),
        };
        getSentParcels(date)
            .then(({data}) => {
                const list_ = data.map(v => {
                    switch (Number(v.sensitive_goods)) {
                        case 0:
                            v.sensitive_goods = t('simple_goods');
                            break;
                        case 1:
                            v.sensitive_goods = t('sensitive_goods');
                            break;
                        case 2:
                            v.sensitive_goods = t('pure_buttery');
                            break;
                        default:
                            break;
                    }

                    v[SUM_2] = Number(
                        v[SUM_2]
                            .toString()
                            .replace(',', '.')
                            .replace(' ', '')
                            .replace(' ', '')
                    )
                        .toFixed(2)

                    return v;
                }).map(parcel => {
                    parcel.list_type = 'sent-parcels';
                    return parcel;
                }).sort( (p1, p2) => Number(new Date(p2.time_of_create)) - Number(new Date(p1.time_of_create)))
                dispatch(setList(list_, t('noStatus')))
                return {
                    data: list_
                }
            })
            .then(data => {
                orderedHook(data.data)
                return data;
            })
            .catch(({response}) => viewAlert(dispatch, response))
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDoubleClick(event, parcel, ref) {
        trackEvent(AMPLITUDE_EVENTS.VIEW_PARCEL_DETAILS, {
            pageSection: 'sent_parcels',
            parcelCode: parcel.code
        });
        dispatch(setModalForm({
            title: 'Parcel details',
            form: <SentParcelDetailForm data={parcel}/>,
            fullScreen: true,
            hideOut: true,
        }));
    }

    function handleCheck(value, item, ref) {
        trackEvent(AMPLITUDE_EVENTS.SELECT_PARCEL, {
            pageSection: 'sent_parcels',
            parcelCode: item.code,
            selected: !item.check
        });
        dispatch(setList(list.map(parcel => {
            if (item.uid === parcel.uid)
                parcel.check = !parcel.check;
            return parcel;
        })));
    }

    function handlePrint() {
        trackEvent(AMPLITUDE_EVENTS.PRINT_STICKER_SENT_PARCELS, {
            pageSection: 'sent_parcels',
            selectedCount: list.filter(item => item.check).length
        });
        dispatch(setModalForm({
            title: '',
            form: <PrintSettingsForm/>,
            fullScreen: true,
        }));
    }

    function handleClickParcel(event, item, ref) {
        if (event && item) {
            trackEvent(AMPLITUDE_EVENTS.SELECT_PARCEL, {
                pageSection: 'sent_parcels',
                parcelCode: item.code,
                action: 'click'
            });
        }
    }


    function handleAllChek(value, id, ref) {
        trackEvent(AMPLITUDE_EVENTS.SELECT_ALL_PARCELS, {
            pageSection: 'sent_parcels',
            selected: value,
            itemsCount: list.length
        });
        const newList = [...list];
        (listAfterFilter && listAfterFilter.length ? listAfterFilter : list).forEach(parcel => {
            const numEl = list.findIndex(el => el.uid === parcel.uid)
            if (numEl >= 0) newList[numEl].check = value;
        });
        dispatch(setList(newList));
        setCheckedAll(value);
    }



    function handlePay(listForPay) {
        trackEvent('payment_initiated', {
            pageSection: 'sent_parcels',
            itemsCount: listForPay?.length || 0
        });
        console.log('handlePay: ', listForPay);
    }

    function handleSelectedToPay(event, modifyList) {
        trackEvent('select_for_payment', {
            pageSection: 'sent_parcels',
            itemsCount: modifyList?.filter(item => item.check)?.length || 0
        });
        dispatch(setList(modifyList));
    }

    const listHead = [
        {
            name: SELECTED, col: 'flex-0-0-0', text: '+', sortArrow: false, getChildren: (v, k) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={handleAllChek}
                    value={checkedAll}
                />
            }
        },
        {name: SUM_2, col: 2, text: t('freight_cost')},
        {name: CHINA_TRACK, col: 2, text: t('Domestic_express_tracking')},
        {name: DATE, col: 2, text: t('date_sent')},
        {name: TRACK_NUMBER, col: 4, text: t('track_number'), sortArrow: false, withoutSort: true},
        {name: COMMODITY, col: 2, text: t('commodity')},
        {name: ESTIMATED_DELIVERY, col: 3, text: t('Estimated_date_delivery_customs')},
        {name: WEIGHT, col: 1, text: t('weight'), classNames: 'px-0', classNamesSortBlock: 'ml-05 mr-05'},
        {name: TRACKER, col: 2, text: t('tracking')},
        {name: SEND_BY, col: 1, text: t('ship')},
        {name: TO_COUNTRY, col: 2, text: t('to')},
        {name: CONSIGNEE, col: 2, text: t('consignee')},
    ]

    const listItem = [
        {
            name: SELECTED, col: 'flex-0-0-0', isTrackNumber: false, getChildren: (parcel) => {
                return <CheckBox
                    className={'check-input mt-children-input-0'}
                    styles={{fontSize: '20px'}}
                    handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                    value={!!parcel.check}
                />
            }
        },
        {
            name: SUM_2, col: 2, isTrackNumber: false, getChildren: parcel => {
                return <div className={'text-minimise '}>
                    <span style={{color: parcel.is_paid === 'true' ? '#2da771' : '#EC1944'}}>{parcel[SUM_2]}</span>
                </div>
            }
        },
        {name: TRACK_NUMBER, col: 2, isTrackNumber: false},
        {name: DATE, col: 2, isTrackNumber: false},
        {
            name: CODE, col: 4, isTrackNumber: true,
            click: (event, parcel, ref) => {
                const urlPath = i18n.language === 'cn' ? '/huowuzhuizong' : '/en/tracking';
                window.open(`https://meestcn.cn${urlPath}/?search=${parcel.code}`, '_blank');
            },
            attr: {"no-select": 'no-select'},
            copy: true,
        },
        {name: COMMODITY, col: 2, isTrackNumber: false},
        {name: ESTIMATED_DELIVERY, col: 3, isTrackNumber: false, getChildren: (parcel) => {
                const estimated_delivery = (parcel[ESTIMATED_DELIVERY] && typeof parcel[ESTIMATED_DELIVERY] === 'string') ? parcel[ESTIMATED_DELIVERY].split("T")[0] : t('not_determined');
                return estimated_delivery
            }},
        {name: WEIGHT, col: 1, text: t('weight'), classNames: 'px-0'},
        {
            name: TRACKER, col: 2, isTrackNumber: false, afterLoadData: true, getChildren: parcel => {
                switch (parcel.status) {
                    case '10': {
                        parcel.tracker = t('returned')
                        break;
                    }
                    case '19': {
                        parcel.tracker = t('write_off_parcel')
                        break;
                    }

                    default:
                        break;
                }
                return parcel.tracker;
            }
        },
        {name: SEND_BY, col: 1, isTrackNumber: false},
        {name: TO_COUNTRY, col: 2, isTrackNumber: false},
        {name: CONSIGNEE, col: 2, isTrackNumber: false},
    ]

    const listItemMobile = {
        firstColumn: [
            {
                name: CODE, nameColumn: t('track_number'), col: 24, isTrackNumber: true,
                click: (event, parcel, ref) => {
                    const urlPath = i18n.language === 'cn' ? '/huowuzhuizong' : '/en/tracking';
                    window.open(`https://meestcn.cn${urlPath}/?search=${parcel.code}`, '_blank');
                },
                attr: {"no-select": 'no-select'},
                copy: true,
            },
            {name: COMMODITY, nameColumn: t('commodity'), col: 24, isTrackNumber: false},
            {name: TO_COUNTRY, nameColumn: t('to'), col: 24, isTrackNumber: false},
            {name: TRACKER, nameColumn: t('tracking'), col: 12, isTrackNumber: false, afterLoadData: true},
        ],
        secondColumn: [
            {
                name: SELECTED, nameColumn: t(''), col: 24, isTrackNumber: false, classTableItem: 'w-100',
                getChildren: (parcel) => {
                    return <div className={'d-flex justify-content-between'}>
                        <div className={'text-minimise '}>
                           <span style={{
                               color: parcel.is_paid === 'true' ? '#2da771' : '#EC1944',
                               fontSize: '10px'
                           }}>{parcel[SUM_2]}</span>
                        </div>
                        <CheckBox
                            className={'check-input'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                            value={!!parcel.check}
                        />
                    </div>
                }
            },
            {name: DATE, nameColumn: t('date_sent'), col: 24, isTrackNumber: false},
            {name: QTY, nameColumn: t('qty_2'), col: 8, isTrackNumber: false},
            {name: WEIGHT, nameColumn: t('weight'), col: 8, isTrackNumber: false},
            {name: SEND_BY, nameColumn: t('ship'), col: 8, isTrackNumber: false},
            {name: CONSIGNEE, nameColumn: t('consignee'), col: 24, isTrackNumber: false},
            {
                name: ESTIMATED_DELIVERY,
                col: 24,
                isTrackNumber: false,
                getChildren: (parcel) => {
                    const estimated_delivery = (parcel[ESTIMATED_DELIVERY] && typeof parcel[ESTIMATED_DELIVERY] === 'string') ? parcel[ESTIMATED_DELIVERY].split("T")[0] : t('not_determined');
                    return <>
                        <span className={'name-column text-wrap'}>{t('Estimated_date_delivery_customs')}</span>
                        <br/>
                        <span className={'text-wrap'}>{estimated_delivery}</span>
                    </>
                }
            },
        ]
    }

    function setDataAfterFilter(data) {
        setListAfterFilter([...data])
    }

    return (
        <div className={'container-page h-100'}>
            <ActionBlock
                handleSearch={handleSearch}
                handleExport={handleExport}
                handlePrint={handlePrint}
                openGroupSearch={openGroupSearch}
                exportFilename={filename}
                handleDatePicker={handleDatePicker}
                handlePay={handlePay}
                handleSelectedToPay={handleSelectedToPay}
                selectedList={list.filter(v => !!v.check && v.is_paid === 'false')}
            />
            <PageListTemplate
                srcOrder={order}
                uniqueRowId={'uid'}
                classesRow={'cursor-pointer'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={t('noSentParcels')}
                disableSelect
                handleClick={handleClickParcel}
                handleDoubleClick={handleDoubleClick}
                listForFilter={withGroupFilter ? groupSearchesCheckboxesCodes : null}
                orderedHook={data => setDataAfterFilter(data)}
                page={'sentParcels'}
            />
            <PageMenu stylesMenuList={{width: '12rem'}}>
                <FilterListBtn page={'sentParcels'} isMobile={true}/>
                <ExportButton handle={handleExport} filename={filename}/>
                <PrintStickerButton handle={handlePrint}/>
                <ButtonStyleAction handle={openGroupSearch} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
            </PageMenu>
        </div>
    );
};

export default SentParcels;
