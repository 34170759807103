

import React, {  }          from 'react';
import { useDispatch }      from "react-redux";
import { useTranslation }   from "react-i18next";

import FeedbackForm         from "../forms/FeedbackForm";
import { setModalForm }     from "../../storage/global";
import {AMPLITUDE_EVENTS, trackEvent} from "../../utils/amplitudeTracker";

const WriteReport = ({isMobile, classNameContainer = '', underText}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    function writeReport() {
        trackEvent(AMPLITUDE_EVENTS.FEEDBACK_CLICK, {
            pageSection: 'header',
            deviceType: 'desktop'
        });
        dispatch(setModalForm({
            title: <div style={{wordWrap: "break-word", marginRight: '46px'}}>{t('feedback_title')}</div>,
            form: <FeedbackForm/>,
            formBody: isMobile ? {classNames: 'modal-form-body-feedback-small'} : undefined,
            formContent: {classNames: ''},
            hideOut: true
        }))
    }

    return (
        <div onClick={writeReport}
             className={`${classNameContainer}`}
             style={{
                width: isMobile ? '70px' : '100px',
                height: '24px',
             }}
        >
            <div className={'cursor-pointer'}
                 style={{
                    width: '100%',
                    marginTop: isMobile ? '3px' : '0px',
                 }}
            >
                <img className={'w-100'} src={`/assets/img/feedback.png`} alt="new"/>
                <div className={`my-text-primary wmc mx-auto ws-nowrap ${isMobile ? 'fs-12px' : 'mt-1'}`}>{underText}</div>
            </div>
        </div>
    );
};

export default WriteReport;
