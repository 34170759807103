import React, { memo } from 'react';
import HeaderLarge from "./HeaderLarge";
import HeaderSmall from "./HeaderSmall";

const Header = ({
    onBackToWebsite,
    onFeedbackClick,
    onInstructionClick,
    onLanguageChange,
    onLogout,
    onNotificationClick,
    onProfileClick,
}) => {
    return (
        <header className={'container-fluid'}>
            <HeaderLarge
                className={'d-none d-xl-flex'}
                onBackToWebsite={onBackToWebsite}
                onFeedbackClick={onFeedbackClick}
                onInstructionClick={onInstructionClick}
                onLanguageChange={onLanguageChange}
                onLogout={onLogout}
                onNotificationClick={onNotificationClick}
                onProfileClick={onProfileClick}
            />
            <HeaderSmall
                className={'d-xl-none'}
                onBackToWebsite={onBackToWebsite}
                onFeedbackClick={onFeedbackClick}
                onInstructionClick={onInstructionClick}
                onLanguageChange={onLanguageChange}
                onLogout={onLogout}
                onNotificationClick={onNotificationClick}
                onProfileClick={onProfileClick}
            />
        </header>
    );
};

export default memo(Header);
