import React, { useMemo } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { setAlert, setModalForm } from "../../../storage/global";
import PayParcelsForm from "../../forms/PayParcelsForm";
import PayParcelsMobileForm from "../../forms/PayParcelsMobileForm";
import FullWidthModalFormMobile from "../../shared/modal/FullWidthModalFormMobile";

const PaySelectedButton = ({className, handle, children, selectedList, classNameBtn = ''}) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const isMobile = useMemo(() => window.innerWidth <= 768, []);
    const disabled = useMemo(() => !(selectedList?.length), [selectedList]);

    function onClick(event) {
        if (typeof handle === 'function') {
            handle(selectedList, event);
        }

        if (!disabled) {
            if (Array.isArray(selectedList) && selectedList.length) {
                if (isMobile) {
                    // Use FullWidthModalFormMobile component
                    const modalTitle = t('pay').slice(0, 1).toUpperCase() + t('pay').slice(1);
                    const modalForm = (
                        <FullWidthModalFormMobile
                            isOpen={true}
                            title={modalTitle}
                            form={<PayParcelsMobileForm parcels={selectedList}/>}
                            fullScreen={true}
                        />
                    );

                    dispatch(setModalForm({
                        show: true,
                        withoutClose: false,
                        title: modalTitle,
                        form: modalForm,
                        fullScreen: true,
                        styles: {
                            backgroundColor: '#fff'
                        },
                        formContent: {
                            classNames: 'mobile-modal-content h-full flex flex-col mobile-payment-modal'
                        },
                        formBody: {
                            classNames: 'mobile-modal-body modal-form-body-with-title'
                        }
                    }));
                } else {
                    dispatch(setModalForm({
                        title: t('pay').slice(0, 1).toUpperCase() + t('pay').slice(1),
                        form: <PayParcelsForm parcels={selectedList}/>,
                        fullScreen: true
                    }));
                }
            } else {
                dispatch(setAlert({
                    text: t('nothingSelected'),
                    type: 'warning'
                }));
            }
        }
    }

    return (
        <div onClick={onClick} className={`btnWarePaySelected_container ${className ? className : ''}`}>
            <Button disabled={disabled} variant={'primary'} className={`btnWarePaySelected ${classNameBtn} ${isMobile ? 'mobile-pay-btn' : ''}`}>
                <span className={`mr-1 ${i18n.language === 'cn' ? 'fs-12px-i' : ''}`}>{t('pay_selected')}</span>
                {!disabled && (
                    <div
                        className="ml-2 bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm number-pay">
                        {selectedList.length}
                    </div>
                )}
            </Button>
        </div>


)
    ;
};

export default PaySelectedButton;
