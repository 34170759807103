import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ReactTooltip        from "react-tooltip";
import { trackEvent, AMPLITUDE_EVENTS } from "../../utils/amplitudeTracker";

import ActionBlockMobile from "./ActionBlockMobile.jsx";
import PromoCodeNumberMobile from "./PromoCodeNumberMobile.jsx";
import localStyles from './styles.module.scss'
import {
    setList,
} from "../../storage/global.js";

import {
    Promo_code_number,
    VALUE,
    STARTDATE,
    FINISHDATE,
    The_country_for_which_it_operates,
} from "../../constants.js";

import PageListTemplate from "../../components/shared/PageListTemplate.jsx";
import CopyIcon from "../../components/shared/CopyIcon.jsx";

const gCount = 7; // generalizing count
const PromoCodes = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const promoCodesList = useSelector(state => state.global.promoCodesList);

    const [ref, setRef] = useState({});

    useEffect(() => {
        dispatch(setList(promoCodesList));
        return () => {
            dispatch(setList([]));
        }
    }, [promoCodesList])

    function handlePromoCodeCopy(promoCode) {
        trackEvent(AMPLITUDE_EVENTS.REF_PROMOCODE_CLICK, {
            pageSection: 'promo_codes',
            promoCode: promoCode[Promo_code_number],
            isActive: promoCode.is_active
        });
    }

    function handleHover(event, ref_, isHover = true) {
        event.preventDefault();
        event.stopPropagation();
        if (isHover)
            ReactTooltip.show(ref_)
        else
            ReactTooltip.hide(ref_)
    }

    const listHead = [
        {
            name: '',
            col: 1,
            sortArrow: false,
            text: '',
            classNames: '',
        },
        {
            name: Promo_code_number,
            col: 3,
            sortArrow: false,
            text: t('Promo_code_number'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Promo_code_number')}</div>
        },
        {
            name: VALUE,
            col: 1,
            sortArrow: false,
            text: t('Promo_code_amount'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Promo_code_amount')}</div>
        },
        {
            name: 'CURRENCY',
            col: 1,
            sortArrow: false,
            text: t('Currency'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Currency')}</div>
        },
        {
            name: STARTDATE,
            col: 1,
            sortArrow: false,
            text: t('Activation_day'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Activation_day')}</div>
        },
        {
            name: FINISHDATE,
            col: 1,
            sortArrow: false,
            text: t('Validity_Date'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>{t('Validity_Date')}</div>
        },
        {
            name: The_country_for_which_it_operates,
            col: 3,
            sortArrow: false,
            text: t('The_country_for_which_it_operates'),
            classNames: 'flex-1-0 justify-content-center',
            getChildren: (el) => <div className={'text-bold fs-12px'}>
                <span className={`pe-2 cursor-pointer`}
                      ref={ref => setRef(ref)}
                      data-html="true"
                      data-tip={`<div class="wb-normal">${t('Using_Your_Promo_Code')+'<br/>'+t('All_countries_hint')}</div>`}
                      onMouseOver={event =>  handleHover(event, ref)}
                      onMouseOut={event => handleHover(event, ref, false)}
                >
                    <img width='20px' height='20px' className={''} src={'/assets/img/question-blue.svg'} alt="question"/>
                </span>
                {t('The_country_for_which_it_operates')}
            </div>
        },
    ]

    const listItem = [
        {
            name: '',
            col: 1,
            sortArrow: false,
            text: '',
            classNames: ''
        },
        {
            name: Promo_code_number,
            col: 3,
            isTrackNumber: false,
            classNames: 'flex-1-0',
            classTableItem: `d-flex justify-content-center`,
            tooltip: (promoCode) => {
                return !promoCode.is_active ? `<div class="wb-normal">${t('promo_code_will_become')}</div>` : promoCode[Promo_code_number]
            },
            getChildren: (promoCode) => {
                return <div className={`d-flex justify-content-center`}>
                    <div onClick={() => handlePromoCodeCopy(promoCode)}
                         className={`text-minimise px-2`}
                         style={promoCode.is_active ? {
                             width: '100%',
                             fontSize: '10px',
                             lineHeight: '20px',
                             color: '#0060AE',
                             fontWeight: 700,
                             textDecorationLine: 'underline',
                         } : {
                             width: '100%',
                             fontSize: '10px',
                             lineHeight: '20px',
                             color: '#8493A8',
                             fontWeight: 700,
                         }}
                    >
                        {promoCode[Promo_code_number]}
                    </div>
                    {promoCode.is_active ? <CopyIcon value={promoCode[Promo_code_number]} onClick={() => handlePromoCodeCopy(promoCode)} styles={{height: '20px'}}/> : null}
                </div>
            }
        },
        {
            name: VALUE,
            col: 1,
            isTrackNumber: false,
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => {
                return <div className={`w-100 d-flex justify-content-center ${promoCode.is_active ? '' : 'name-column-color'}`}>
                    {promoCode[VALUE]}
                </div>
            }
        },
        {
            name: 'CURRENCY',
            col: 1,
            isTrackNumber: false,
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => {
                return <div className={`w-100 d-flex justify-content-center ${promoCode.is_active ? '' : 'name-column-color'}`}>
                    {promoCode.currency}
                </div>
            }
        },
        {
            col: 1,
            isTrackNumber: false,
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => {
                return <span className={`p-1 ${promoCode.is_active ? '' : 'name-column-color'}`}>
                    {promoCode[STARTDATE]?.slice(0,10)}
                </span>
            },
        },
        {
            col: 1,
            isTrackNumber: false,
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => {
                return <span className={`p-1 ${promoCode.is_active ? '' : 'name-column-color'}`}>
                    {promoCode[FINISHDATE]?.slice(0,10)}
                </span>
            },
        },
        {
            name: The_country_for_which_it_operates,
            col: 3,
            text: t('The_country_for_which_it_operates'),
            classNames: 'flex-1-0',
            classTableItem: 'w-100 d-flex justify-content-center table-item-text',
            getChildren: (promoCode) => {
                const cEU = promoCode.countriesEU;
                const cNotEU = promoCode.countriesNotEU;
                const text = getCountriesListText(cEU, cNotEU);
                return <span className={`${promoCode.is_active ? '' : 'name-column-color'}`}>
                {text}
            </span>;
            }
        },
    ]

    function getCountriesListText(cEU = [], cNotEU = []) {
        const euCount = cEU.length;
        const notEuCount = cNotEU.length;

        if (euCount >= 8 && notEuCount <= 7) {
            return `${t('EU')}*${notEuCount ? ', ' + cNotEU.join(', ') : ''}`;
        }

        if (notEuCount >= 8 && euCount <= 7) {
            return `${t('All_non_EU')}*${euCount ? ', ' + cEU.join(', ') : ''}`;
        }

        if (euCount + notEuCount > 7) {
            return t('All_countries') + '*';
        }

        if (!notEuCount && euCount <= 7) return cEU.join(', ');
        if (!euCount && notEuCount <= 7) return cNotEU.join(', ');

        return cEU.join(', ') + ', ' + cNotEU.join(', ');
    }

    const listItemMobile = {
        firstColumn: [
            {
                name: Promo_code_number,
                col: 24,
                copy: true,
                classNamesChildren: `${localStyles.children}`,
                getChildren: (promoCode) => {
                    return <PromoCodeNumberMobile promoCode={promoCode}/>}
            },
            {
                name: VALUE,
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => <>
                    <span className={'name-column text-wrap text-bold fs-12px'}>{t('Promo_code_amount')}</span>
                    <br/>
                    <span className={`p-1 ${promoCode.is_active ? '' : 'name-column-color'}`}>{promoCode[VALUE]}</span>
                </>
            },
            {
                name: 'CURRENCY',
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => <>
                    <span className={'name-column text-wrap text-bold fs-12px'}>{t('Currency')}</span>
                    <br/>
                    <span className={`p-1 ${promoCode.is_active ? '' : 'name-column-color'}`}>{promoCode.currency}</span>
                </>
            }
        ],
        secondColumn: [
            {
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => <>
                    <span className={'name-column text-wrap text-bold fs-12px'}>{t('Activation_day')}</span>
                    <br/>
                    <span className={`p-1 ${promoCode.is_active ? '' : 'name-column-color'}`}>{promoCode[STARTDATE]?.slice(0,10)}</span>
                </>
            },
            {
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => <>
                    <span className={'name-column text-wrap text-bold fs-12px'}>{t('Validity_Date')}</span>
                    <br/>
                    <span className={`p-1 ${promoCode.is_active ? '' : 'name-column-color'}`}>{promoCode[FINISHDATE]?.slice(0,10)}</span>
                </>
            },
            {
                col: 24,
                isTrackNumber: false,
                getChildren: (promoCode) => {
                    const cEU = promoCode.countriesEU;
                    const cNotEU = promoCode.countriesNotEU;
                    const text = getCountriesListText(cEU, cNotEU);
                    return <>
                        <span className={'name-column text-wrap text-bold fs-12px'}>{t('The_country_for_which_it_operates')}</span>
                        <br/>
                        <span className={`text-wrap ${promoCode.is_active ? '' : 'name-column-color'}`}>{text}</span>
                    </>
                }
            },
        ]
    }

    return (
        <div className={'container-page h-100 promo-codes'}>
            <ActionBlockMobile onPromocodesArticles={() => {
                trackEvent(AMPLITUDE_EVENTS.PROMOCODES_ARTICLES_CLICK, {
                    pageSection: 'promo_codes'
                });
            }}/>
            <PageListTemplate
                srcOrder={{}}
                uniqueRowId={'TrackNumber'}
                classesRow={'cursor-pointer'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={''}
                disableSelect
                headList2={true}
            />
        </div>
    )
}

export default PromoCodes;
