import React            from 'react';
import { useDispatch }  from "react-redux";

import { setModalForm } from "../../storage/global";
import VideoInstr       from "../misc/VideoInstr";
import {AMPLITUDE_EVENTS, trackEvent} from "../../utils/amplitudeTracker";

const HelpVideo = ({className}) => {

    const dispatch = useDispatch();

    function openVideo() {
        trackEvent(AMPLITUDE_EVENTS.INSTRUCTION_CLICK, {
            pageSection: 'header',
            deviceType: 'desktop'
        });
        dispatch(setModalForm({
            title: 'Help video',
            form: <VideoInstr/>,
            fullScreen: true
        }))
    }

    return (
        <div>
            <img onClick={openVideo} className={`${className} cursor-pointer`} src="/assets/img/help_video.svg" alt="help-video" height="24px"/>
        </div>
    );
};

export default HelpVideo;
