import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const EnvelopeRain = ({ envelopeCount = 100 }) => {
    const [showAnimation, setShowAnimation] = useState(false);
    const [isEnding, setIsEnding] = useState(false);
    const profile = useSelector(state => state.global.profile);

    const shouldShowAnimation = () => {
        if (!profile?.usercode) return false;

        const today = new Date().toISOString().split('T')[0];
        const lastShown = localStorage.getItem(`envelope_rain_${profile.usercode}`);

        return lastShown !== today;
    };

    const markAnimationAsShown = () => {
        if (profile?.usercode) {
            const today = new Date().toISOString().split('T')[0];
            localStorage.setItem(`envelope_rain_${profile.usercode}`, today);
        }
    };

    const createEnvelopes = () => {
        const envelopes = [];
        const numColumns = Math.ceil(Math.sqrt(envelopeCount * 1.5));
        const columnWidth = 100 / numColumns;

        for (let i = 0; i < envelopeCount; i++) {
            const column = Math.floor(Math.random() * numColumns);
            const basePosition = column * columnWidth;
            const offset = Math.random() * (columnWidth * 0.8);
            const finalPosition = basePosition + offset;

            envelopes.push({
                id: i,
                left: `${Math.min(Math.max(finalPosition, 0), 98)}%`,
                delay: `${Math.random() * 4}s`,
                startPosition: `${-100 - Math.random() * 300}vh`,
            });
        }
        return envelopes;
    };

    const initialEnvelopes = createEnvelopes();

    useEffect(() => {

        if (profile?.usercode && shouldShowAnimation()) {

            const timer = setTimeout(() => {
                setShowAnimation(true);
                markAnimationAsShown();

                setTimeout(() => {
                    setIsEnding(true);
                }, 6000);

                setTimeout(() => {
                    setShowAnimation(false);
                }, 7000);
            }, 10000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [profile]);

    useEffect(() => {
        window.startEnvelopeAnimation = () => {
            if (shouldShowAnimation()) {
                setShowAnimation(true);
                markAnimationAsShown();

                setTimeout(() => {
                    setIsEnding(true);
                }, 6000);

                setTimeout(() => {
                    setShowAnimation(false);
                }, 7000);
            } else {
                // console.log('EnvelopeRain: Animation already shown today');
            }
        };
        return () => {
            delete window.startEnvelopeAnimation;
        };
    }, [profile]);

    if (!showAnimation) {
        return null;
    }

    return (
        <div className={`rain-container ${isEnding ? 'ending' : ''}`}>
            {initialEnvelopes.map(envelope => (
                <div
                    key={envelope.id}
                    className="envelope"
                    style={{
                        left: envelope.left,
                        animationDelay: envelope.delay,
                        transform: `translateY(${envelope.startPosition})`
                    }}
                >
                    <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <rect x="17" y="5" width="38" height="62" fill="#d22f27"/>
                            <circle cx="36" cy="27" r="10" fill="#f1b31c"/>
                        </g>
                        <g>
                            <circle cx="36" cy="27" r="10" fill="none" stroke="#000000" strokeWidth="2"/>
                            <rect x="17" y="5" width="38" height="62" fill="none" stroke="#000000" strokeWidth="2"/>
                        </g>
                    </svg>
                </div>
            ))}
            <style>
                {`
                    .rain-container {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100vw;
                        height: 100vh;
                        pointer-events: none;
                        z-index: 9999;
                    }

                    .rain-container.ending {
                        animation: fadeOut 1s forwards;
                    }

                    .envelope {
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        animation: fall 3s linear;
                        opacity: 0;
                        will-change: transform;
                    }

                    .envelope svg {
                        width: 100%;
                        height: 100%;
                    }

                    @keyframes fall {
                        0% {
                            transform: translateY(-100vh) rotate(0deg);
                            opacity: 1;
                        }
                        85% {
                            opacity: 1;
                        }
                        100% {
                            transform: translateY(100vh) rotate(360deg);
                            opacity: 0;
                        }
                    }

                    @keyframes fadeOut {
                        to {
                            opacity: 0;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default EnvelopeRain;
